import React,{useState} from 'react'
import {Box,IconButton,Table,TableBody,TableCell,LinearProgress,
      //,Button,Collapse,Paper,
      TableContainer,TableHead,TableRow,Card,Tooltip,
      TablePagination,} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import qs from 'qs';
import XLSX from 'xlsx';
import moment from 'moment';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ModalMapa from './ModalMapa'
import LocationOnIcon from '@material-ui/icons/LocationOn';
import InfoPromo from './ModInfo';
import InfoIcon from '@material-ui/icons/Info';
import ModalNvoPerfil from '../nvoPerfil/ModalNvoPerfil'
import ModalNvoPerfilImg from '../nvoPerfil/ModalNvoPerfImg';
import ModalAvisoPerfil from '../nvoPerfil/ModalAvisoPerfil';
import ImageIcon from '@material-ui/icons/Image';
import SaveIcon from '@material-ui/icons/Save';
import CreateIcon from '@material-ui/icons/Create';
import ModalEditPerfil from '../EditarPerfil/ModalEditPerfil';
import PaginacionTab from '../layout/PaginacionTab'
import { numeroPag } from "../funciones/Paginacion";
import {ordenMapBx,ordenIdColor} from "../MapaEdo/OrdenxMapBox"
const useStyles = makeStyles({
    root: {
      //width: '100%',
    },
    container: {
      //maxHeight: 550,
      maxHeight: window.innerHeight-252,
      minHeight: 280,
      maxWidth:'100%',
      minWidth:60+'rem',
    },
    container2: {
      maxHeight: 550,
      //maxHeight: window.innerHeight-170,
      //minHeight: 280,
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor: process.env.REACT_APP_Color_Second
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });
  const columnas=[          
  /* {
      id:2,
      titulo:"",                                                
      alinear:'center'
    }, */
    {
      id:3, titulo:"Registro", alinear:'center'
    }, 
    {
      id:8,titulo:"Municipio", alinear:'center'
    },
    {
      id:7,titulo:"Sección", alinear:'center'
    },
    {
      id:6, titulo:"Teléfono",alinear:'center'
    }, 
    {
      id:10, titulo:"Usuario",alinear:'center'
    },
    {
      id:11, titulo:"Nombre",alinear:'center'
    },
    {
      id:9, titulo:"Email", alinear:'center'
    }, 
    {
      id:5, titulo:"Verificación",alinear:'center'
    },     
    {
      id:4, titulo:"Nacimiento",alinear:'center'
    },       
  ];

const TablaPerfilGeneral = ({fSelect,actualizar,setActualizar,setArregloColor,setTotalPerfil,setInfoNvlCoord,listMun,setListMun}) => {
  const classes = useStyles();
  let history = useHistory();         
  const [datos, guardarDatos] = React.useState([])  
  const[sinDatos,guardarSinDatos] = React.useState(true)
  const [espera,setEspera] = React.useState(false);
  const [selectedID, setSelectedID] = React.useState(null);  
  const [regisSelect, setRegisSelect] = React.useState([]);
  const [modalNvoPerfil, setModalNvoPerfil] = React.useState(false);
  const [modalEditPerfil, setModalEditPerfil] = React.useState(false);
  const [modalNvoImg, setModalNvoImg] = React.useState(false);
  //const[actualizar,setActualizar]=useState(true);  
  const [infoMapaMod, setInfoMapaMod] = useState(false);
  const [subtInfo, setSubtInfo] = useState("")
  //const [tituloNuevo, setTituloNuevo] = useState("")
  const [tituloMapa,setTituloMapa] = useState("")
  const [modalInfo, setModalInfo] = useState(false);
  //const [listMun,setListMun]=useState([])
  const [listSecc,setListSecc]=useState([])
  const [listPlantilla,setListPlantilla]=useState([])
  const [modalAvisoImg, setModalAvisoImg] = React.useState(false);
  const [modalAvisoImg2, setModalAvisoImg2] = React.useState(false);
  const [nvoRegisPerfil, setNvoRegisPerfil] = React.useState([]);
  const [listaExcel, setListaExcel] = React.useState([]);
  const [totalPaginas,setTotalPaginas]=useState(0);
  const [numPagina,setNumPagina]=useState(0);
  const [listPagina,setListPagina]=useState([]);
  let tamMax=200
  const usuID = localStorage.getItem('UsuId');
  let fondo=process.env.REACT_APP_Fondo_Color
  let colSecond=process.env.REACT_APP_Color_Second
  React.useEffect(()=>
  {
    const source = axios.CancelToken.source();    
    let fInicio=moment(fSelect.inicio).format("YYYY-MM-DD");
    let fFinal=moment(fSelect.final).format("YYYY-MM-DD");
    //const usuID = localStorage.getItem('UsuId');
    let usuTipo =localStorage.getItem('Tipo') ;    

    async function conectarJSon()  {       
      setEspera(true) ;
      
      let auxU= process.env.REACT_APP_LINK +`perfil-lista`;               
      let token =localStorage.getItem('token20') ;               
      let dataInfo = qs.stringify({                
        'usuario':usuID,
        'fini':fInicio+'T00:00:00',
        'ffin':fFinal+'T23:59:59',
        'status':0,
        'idDispositivo':'w8rf51v21dsd2cs',
          });
      let config = {
          url:auxU ,
          method: 'POST',
          headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
          data: dataInfo,
          cancelToken: source.token
      };
      let aux ={};           
      await axios(config)
      .then(function (response) {                
        aux=  response.data;                           
        if (aux[0].Id !== -1) 
        { // console.log(aux);  
          guardarSinDatos(true)                                                           
          
          //let auxTotal=[]
          let auxPendiente=0
          let auxVerificado=0
          let auxRechazado=0
          let auxArrMuni=[],auxPos=[]
          let arrM=[]
          arrM.push(["#","Municipio","Sección","Nombre","Teléfono","Email","Fecha Registro","Fecha Nacimiento","Foto"])
          aux.forEach((perfil,index) => {
            if (perfil.StatusId===1) 
            { auxPendiente=auxPendiente+1
            }
            if (perfil.StatusId===2) 
            { auxVerificado=auxVerificado+1
            }
            if (perfil.StatusId===3) 
            { auxRechazado=auxRechazado+1
            }
            let idColor= ordenIdColor(perfil.MunId )
            let auxMuni=perfil.MunNom.charAt(0)+ perfil.MunNom.slice(1).toLowerCase()   
            let auxFReg=moment.utc( perfil.Fecha).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00"
            ? "" : moment.utc( perfil.Fecha).format('DD/MM/YYYY HH:mm')
            let auxNac=moment.utc( perfil.Nacimiento).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00"
            ? "" : moment.utc( perfil.Nacimiento).format('DD/MM/YYYY') 
            arrM.push([index+1,auxMuni,perfil.SeccNum,perfil.Nombre,perfil.Tel,perfil.Email,auxFReg,auxNac,perfil.Imagen])
            //auxArrMuni.push({Id:idColor,auxColor:colSecond,IdOriginal:perfil.MunId})
            
            let filtrado=auxPos.findIndex((elemt)=>elemt===idColor)
           // console.log(filtrado,idColor);
            if (filtrado ===-1) {
              ordenMapBx(auxArrMuni,idColor,colSecond) 
              auxPos.push(idColor)
            }
            
          });
          //console.log(auxPos);
          //auxTotal.push()
          setListaExcel(arrM)
          setTotalPerfil({
            Valido:auxVerificado,
            Pendiente:auxPendiente,
            Rechazado:auxRechazado})  
          setArregloColor(auxArrMuni)
          numeroPag(aux,tamMax,setTotalPaginas,setNumPagina,setListPagina)                     
          guardarDatos(aux)      
          
        }
        else
        { setListaExcel([])
          guardarDatos([])
          setListPagina([])
          guardarSinDatos(false)   
          setTotalPerfil([])    
          setArregloColor([])  
        }                                            
      })
      .catch(function (error) {                
        console.log(error);        
        if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
        {
          console.log('datos incorrectos' );  
        }else
        {
          if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
              ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
          {
            console.log('loguearse de nuevo');
            localStorage.clear();                      
            history.push("/");                              
          } 
        }
      });              
      setEspera(false)  ;
    };

    async function llamarListMun()  {       
      setEspera(true) ;      
      let auxU= process.env.REACT_APP_LINK +`lugar-municipio-tipo`;               
      let token =localStorage.getItem('token20') ;               
      let dataInfo = qs.stringify({                
        'usuario':usuID,
        'estado':15,
        'tipo':usuTipo,
        'idDispositivo':'w8rf51v21dsd2cs',
          });
      let config = {
          url:auxU ,
          method: 'POST',
          headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
          data: dataInfo,
          cancelToken: source.token
      };
      let aux ={};           
      await axios(config)
      .then(function (response) {                
        aux=  response.data;                           
        if (aux[0].Id != -1) 
        { setListMun(aux)           
        }
      })
      .catch(function (error) {                
        console.log(error);        
        if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
        {
          console.log('datos incorrectos' );  
        }else
        {
          if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
              ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
          {
            console.log('loguearse de nuevo');
            localStorage.clear();                      
            history.push("/");                              
          } 
        }
      });              
      setEspera(false)  ;
    };

    async function llamarListSecc()  {       
      setEspera(true) ;      
      let auxU= process.env.REACT_APP_LINK +`lugar-seccion-list`;               
      let token =localStorage.getItem('token20') ;               
      let dataInfo = qs.stringify({                
        'usuario':usuID,        
        'idDispositivo':'w8rf51v21dsd2cs',
          });
      let config = {
          url:auxU ,
          method: 'POST',
          headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
          data: dataInfo,
          cancelToken: source.token
      };
      let aux ={};           
      await axios(config)
      .then(function (response) {                
        aux=  response.data;                           
        if (aux[0].Id != -1) 
        { setListSecc(aux)           
        }
      })
      .catch(function (error) {                
        console.log(error);        
        if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
        {
          console.log('datos incorrectos' );  
        }else
        {
          if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
              ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
          {
            console.log('loguearse de nuevo');
            localStorage.clear();                      
            history.push("/");                              
          } 
        }
      });              
      setEspera(false)  ;
    };

    async function llamarListPlantilla()  {       
      setEspera(true) ;      
      let auxU= process.env.REACT_APP_LINK +`plantilla-base-dominio`;               
      let token =localStorage.getItem('token20') ;               
      let dataInfo = qs.stringify({                
        'usuario':usuID,        
        "dominio":"admin.yosoynuevoespacio.mx",
        'idDispositivo':'w8rf51v21dsd2cs',
          });
      let config = {
          url:auxU ,
          method: 'POST',
          headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
          data: dataInfo,
          cancelToken: source.token
      };
      let aux ={};           
      await axios(config)
      .then(function (response) {                
        aux=  response.data;                           
        if (aux[0].Id != -1) 
        { setListPlantilla(aux)           
        }
      })
      .catch(function (error) {                
        console.log(error);        
        if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
        {
          console.log('datos incorrectos' );  
        }else
        {
          if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
              ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
          {
            console.log('loguearse de nuevo');
            localStorage.clear();                      
            history.push("/");                              
          } 
        }
      });              
      setEspera(false)  ;
    };
    
    async function llamadaPerfilEstrutura()  {                       
      let auxU= process.env.REACT_APP_LINK +`perfil-estructura-perfil`;               
      let token =localStorage.getItem('token20') ;               
      let dataInfo = qs.stringify({                
        'usuario':usuID,          
        'tipo':1,
        'zon':0,
        'reg':0,
        'mun':0,
        'sec':0,
        'pro':0,
        'idDispositivo':'w8rf51v21dsd2cs',
          });
      let config = {
          url:auxU ,
          method: 'POST',
          headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
          data: dataInfo,
          cancelToken: source.token
      };
      let aux ={};           
      await axios(config)
      .then(function (response) {                
        aux=  response.data;                           
        if (aux[0].Id !==  -1 ) 
        {  //console.log(aux);  
          setInfoNvlCoord(aux)             
        }                               
      })
      .catch(function (error) {                
        console.log(error);        
        if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
        {
          console.log('datos incorrectos' );  
        }else
        {
          if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
              ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
          {
            console.log('loguearse de nuevo');
            localStorage.clear();                      
            history.push("/");                              
          } 
        }
      });                      
    };
   
    conectarJSon(); 

    if (listMun.length===0) 
    { llamarListMun()      
    }
    if (listSecc.length===0) 
    { llamarListSecc()     
    }
      
    if (listPlantilla.length===0) 
    {
      llamarListPlantilla()     
    }
     
    llamadaPerfilEstrutura()               
             
    return ()=> {
      source.cancel();
    }   
               
  },[actualizar])

  function onChangePagina(event, newPage){
    event.preventDefault();
    if (newPage ===0) 
    {
      setListPagina(datos.slice(0,tamMax))   
    }
    else
    {
      setListPagina(datos.slice(newPage * tamMax, newPage * tamMax + tamMax))   
    }
    setNumPagina(newPage)
  }

  function generarFooter(){
    return(
    <TablePagination
      rowsPerPageOptions={false} colSpan={3}
      rowsPerPage={tamMax} count={datos.length}
      page={numPagina} lang='es'
      labelDisplayedRows={
          ({ from, to, count }) => {
            return '' + from + '-' + to + ' de ' + count
          }
        }
      onPageChange={onChangePagina}
      ActionsComponent={PaginacionTab}/>)
  }

  const exportarArch = () => {
    let today = new Date();                    
    let fec = moment(today);        
    console.log(listaExcel);
    const ws = XLSX.utils.aoa_to_sheet(listaExcel);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Reporte");
    XLSX.writeFile(wb, fec.format("YYMMDDHHmmss")+" Lista General.xlsx")
  };

  React.useEffect(()=>{
    if (nvoRegisPerfil.length!==0 &&!modalAvisoImg2 && !modalNvoImg ) {
      setTimeout(() => {
        //console.log('This will run after 1 second!')       
       setModalAvisoImg(true)
      }, 500);  
    }
    
  },[nvoRegisPerfil])

  const seleccionado=(auxId,auxOrden)=>{
    setSelectedID(auxId)   
    setRegisSelect(auxOrden)
  }      
  
  function tipoEdoProspecto(auxColorStd,etiqueta)
  {  
    let renglon=(
      <TableCell  align="center" padding={'none'}        
        style={{ color:'white',fontWeight:700 ,width:100+'px',paddingLeft:.4+'rem',paddingRight:.4+'rem'}} 
      >
        <Card style={{color:auxColorStd==="#EBF1F8"? "black":'white', fontWeight:"bold", backgroundColor:auxColorStd}}>
          {etiqueta}
        </Card>
      </TableCell>    )
    return renglon
  } 

  function abrirModalMapa(auxSelec){
    let fecha = moment.utc(auxSelec.Fecha).format('YYYY-MM-DD HH:mm:ss') === "1900-01-01 00:00"
    ? "" : moment.utc(auxSelec.Fecha).format('DD/MM/YYYY HH:mm:ss')
    let titulo=`${auxSelec.Id}`;
    setTituloMapa(titulo)
    setSubtInfo(fecha)
    setRegisSelect(auxSelec)
    setInfoMapaMod(true)
  }
  
  function abrirModalInfo(auxSelec){
    setModalInfo(true)
    
    let titulo=`Registro ${auxSelec.Id} `;
    setTituloMapa(titulo)
    setRegisSelect(auxSelec)
  }
  
  function abrirModalNvoPerfil(){  
  //  console.log("abriendo modal");  
    setModalNvoPerfil(true);    
  }
  
  function abrirModalEditPerfil(){      
    setModalEditPerfil(true);    
    }

  function abrirModalNvoPerfilImg(){      
    //console.log(listPlantilla);
    setTimeout(() => {
      console.log('cargando modal img')       
      setModalNvoImg(true);    
    }, 300); 
    //setModalNvoImg(true);    
  }

  const celda=(alinear,estilo,contenido)=>{
    return (
    <TableCell align={alinear} className={classes.tableCell} padding={'none'} style={estilo} >
      {contenido}
    </TableCell>
    )
  }

  const celdaNvoPerfil=()=>{
    return(
    <Tooltip title={`Nuevo Perfil`}>
      <IconButton aria-label="agregar nuevo perfil" onClick={() => abrirModalNvoPerfil()} 
        component="span" size="small" 
      >
        <AddCircleOutlineIcon style={{color:"white" }} />  
      </IconButton>
    </Tooltip> 
    )
  }

  const editarPerfil=(e,row,auxValida)=>{
    e.preventDefault()
    /* if (auxValida===1) {
      //abrirModalNvoPerfilImg()
      setModalNvoImg(true);    
      setNvoRegisPerfil({Nom:row.Nombre,llave:row.Id})  
    }     */
    setModalNvoImg(true);    
    setNvoRegisPerfil({Nom:row.Nombre,llave:row.Id})  
  }

  function Renglon(props)
  {
    const { row } = props; 
    let auxMuni=row.MunNom.charAt(0)+ row.MunNom.slice(1).toLowerCase()   
    let auxNac=moment.utc( row.Nacimiento).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00"
    ? "" : moment.utc( row.Nacimiento).format('DD/MM/YYYY')    
    let auxFReg=moment.utc( row.Fecha).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00"
    ? "" : moment.utc( row.Fecha).format('DD/MM/YYYY HH:mm')
    let leyenda=`Crear Imagen`
    let tipoColor="inherit"
    let auxValida=1
    if(row.Imagen.length > 0)
    {
      leyenda=`Imagen Cargada`
      tipoColor="primary"
      auxValida=0
    }
    
    let auxImg=null
    let imgEstilo=selectedID === row.Id ? {width:18+'px',color:'white',marginTop:.2+'rem'} :{width:18+'px',marginTop:.2+'rem'}
    auxImg = parseInt(usuID) === 1 ?
     (<Tooltip title={leyenda}> 
        <IconButton   aria-label="agregar imagen" size="small" 
          onClick={(e) => editarPerfil(e,row,auxValida)} 
          component="span">
          <ImageIcon style={imgEstilo} color={tipoColor} /> 
        </IconButton>
      </Tooltip> )
    : (<ImageIcon style={imgEstilo} color={tipoColor} />)                               
    let auxInfo= <Tooltip title={`Información de registro`}>
      <IconButton   aria-label="add circle" size="small" 
        onClick={() => abrirModalInfo(row)} component="span">
        {selectedID === row.Id ? 
          <InfoIcon style={{color:'white',width:18+'px'}} />
          :<InfoIcon style={{width:18+'px'}} color="primary"/>
        }
      </IconButton>                     
      </Tooltip>
    let auxMap=  <Tooltip title={`Ver Mapa`}>
      <IconButton   aria-label="add circle" size="small" 
        onClick={() => abrirModalMapa(row)} component="span">
          {selectedID === row.Id ? 
            <LocationOnIcon style={{color:'white',width:18+'px'}} />
            :<LocationOnIcon style={{width:18+'px'}} color="primary"/>
          }
        </IconButton>                            
      </Tooltip>                                                                                                                                              
    let auxEdit=  <Tooltip title={`Editar Perfil`}>
        <IconButton   aria-label="add circle" size="small" 
          onClick={() => abrirModalEditPerfil(row)} component="span">
            {selectedID === row.Id ? 
              <CreateIcon style={{color:'white',width:18+'px'}} />
              :<CreateIcon style={{width:18+'px'}} color="primary"/>
            }
          </IconButton>                            
        </Tooltip>                                  
    return (
    <React.Fragment>
    <TableRow onClick={ () => { seleccionado(row.Id,row); } }
      className={classes.tableRow} selected={ selectedID === row.Id}      
      classes={{ hover: classes.hover, selected: classes.selected }}        
    >
      {usuID && parseInt(usuID) === 1 ? celda("center",{width:10+'px'}, auxEdit):null}
      {celda("center",{width:10+'px'}, auxInfo)}
      {celda("center",{width:10+'px'}, auxMap)}
      {celda("center",{width:10+'px',paddingLeft:.3+'rem'}, auxImg)}
      {/* celda("center",{fontWeight:700,width:40+'px', paddingRight:2+'px'}, row.Id) */}    
      {celda("center",{width:105+'px',fontSize:12+'px',paddingLeft:8+'px'}, auxFReg)}
      {celda("center",{width:220+'px',fontSize:12+'px', paddingRight:2+'px'}, auxMuni)}
      {celda("center",{fontWeight:700,width:40+'px', paddingRight:2+'px'}, row.SeccNum)}        
      {celda("center",{width:50+'px',fontSize:12+'px',paddingLeft:8+'px'}, row.Tel)}
      {celda("left",{width:50+'px',fontSize:12+'px',paddingLeft:8+'px'}, row.UserNom)}  
      {celda("left",{width:220+'px',fontSize:12+'px',paddingLeft:8+'px'}, row.Nombre)}  
      {celda("left",{width:200+'px',fontSize:12+'px',paddingLeft:8+'px'}, row.Email)}                                                                                     
      {tipoEdoProspecto(row.StatusColor,row.StatusNom)}             
      {celda("center",{width:105+'px',fontSize:12+'px',paddingLeft:8+'px'}, auxNac)}                                                                                                                                                                                   
    </TableRow>        
    </React.Fragment>
  );}
  
  const vacia=<TableCell  className={classes.tableCell} align="center"> </TableCell>
  const ColumnaVacia=<TableCell  className={classes.tableCell} align="center" style={{ backgroundColor:fondo,color:'white'}}  > </TableCell>

  function tablaCompleta(auxlista){ 
    return(
    <TableContainer className={classes.container}>
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>  
          {usuID && parseInt(usuID) === 1 ?ColumnaVacia:null}
        {usuID && parseInt(usuID) === 1 ?<TableCell align="center" padding={'none'} style={{ backgroundColor:fondo,color:'white'}}  >     
         <IconButton onClick={exportarArch} size="small" >
            <Tooltip title="Exportar a Excel">
              <SaveIcon style={{color:'white'}} size="small" />
            </Tooltip>
          </IconButton>                                  
          </TableCell>
            :ColumnaVacia}
          <TableCell align="center" padding={'none'} style={{ backgroundColor:fondo,color:'white'}}  >     
            {celdaNvoPerfil() /*  */ }                                  
          </TableCell>   
          <TableCell align="center" padding={'none'} style={{ backgroundColor:fondo,color:'white'}}  >                          
            {auxlista.length}
          </TableCell>                           
          {columnas.map(row => {              
            return(  
            <TableCell style={{ backgroundColor:fondo,color:'white'}} 
              key={row.titulo+row.id}    align={row.alinear} padding={'none'} 
            >
              {row.titulo}
            </TableCell>    
            )                
          })}           
        </TableRow>
      </TableHead>
      <TableBody>
        {auxlista.length!=0 ? (auxlista.map((row) => (
            <Renglon key={"regPerfilGen"+row.Id} row={row} />
        ))):null}
      </TableBody>
    </Table>
    </TableContainer>
    )
  }
    
  const tablaSinDatos=()=>{
    return(
    <TableContainer className={classes.container2}>
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>            
          <TableCell align="center" padding={'none'}  style={{ backgroundColor:fondo,color:'white'}} >              
            {celdaNvoPerfil()  /* */}           
          </TableCell>       
          {columnas.map(row => {              
            return(  
            <TableCell style={{ backgroundColor:fondo,color:'white'}} 
              key={row.titulo+row.id}    align={row.alinear} padding={'none'} 
            >
              {row.titulo}
            </TableCell>    
            )                
          })}                                                
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow  className={classes.tableRow} > 
          {vacia}
          {vacia}  
          {vacia}                
          <TableCell  className={classes.tableCell} align="center">Sin </TableCell>  
          {vacia}               
          <TableCell  className={classes.tableCell} align="center">Registros</TableCell>
          {vacia}      
          {vacia}     
          {vacia}   
          {vacia}      
        </TableRow>
      </TableBody>
    </Table>
    </TableContainer>
  )}
     
  
  const contenido = sinDatos&&listPagina.length!==0 ?tablaCompleta(listPagina):tablaSinDatos()
  const tabla = espera ?  (<Box pt={3}><LinearProgress/> </Box>) :( contenido )
 
  const modalMap = infoMapaMod ? <ModalMapa seleccionado={regisSelect} titulo={tituloMapa} subtitulo={subtInfo}
                                    setModalAbierto={setInfoMapaMod } modalAbierto={infoMapaMod}/> : null;     

  const modInfo = modalInfo ? <InfoPromo seleccionado={regisSelect} modalAbierto={modalInfo} 
                                setModalAbierto={setModalInfo} titulo={tituloMapa} /> : null;            
                                
  const modEditPerf = modalEditPerfil && listPlantilla.length!== 0 ? 
                                  <ModalEditPerfil modalAbierto={modalEditPerfil} setModalAbierto={setModalEditPerfil} 
                                    titulo="Editar Perfil" perfil={regisSelect} listPerfil={datos}
                                    listMun={listMun} listSecc={listSecc}
                                  />:null

  const modNvoPerf = modalNvoPerfil && listPlantilla.length!== 0 ? 
                                  <ModalNvoPerfil modalAbierto={modalNvoPerfil} setModalAbierto={setModalNvoPerfil} 
                                    titulo="Nuevo Perfil" listMun={listMun} listSecc={listSecc} listPlantilla={listPlantilla}
                                    setModalAvisoImg={setModalAvisoImg2} setNvoRegisPerfil={setNvoRegisPerfil}
                                  />:null
  const modNvoImg = modalNvoImg  && listPlantilla.length!== 0  ? 
                                  <ModalNvoPerfilImg modalAbierto={modalNvoImg} setModalAbierto={setModalNvoImg} 
                                    titulo= {nvoRegisPerfil.Nom ? "Perfil "+nvoRegisPerfil.Nom:"Perfil"}  auxPerfImg={listPlantilla}
                                    nvoRegisPerfil={nvoRegisPerfil} actualizar={actualizar} setActualizar={setActualizar}
                                  />:null         
                                     
  const modNvoAviso = modalAvisoImg  ? <ModalAvisoPerfil modalAbierto={modalAvisoImg} setModalAbierto={setModalAvisoImg} 
                                        titulo="Validación" abrirModImg={abrirModalNvoPerfilImg} 
                                        actualizar={actualizar} setActualizar={setActualizar}
                                      />:null        
  const paginacion = !espera&&listPagina.length!==0 &&totalPaginas>1 ? generarFooter() : null;                                                                                                                                                                   

  return (
  <div style={{minWidth:55+'rem',maxWidth:65+'rem'}}>        
    {tabla}   
    {paginacion}          
    {modalMap}
    {modInfo}
    {modNvoPerf}
    {modNvoImg}
    {modNvoAviso}
    {modEditPerf}
  </div>
  )
}

export default TablaPerfilGeneral