import React,{useState,useEffect} from 'react';
import {Box,IconButton,Tooltip,TextField, LinearProgress,Card,
  Breadcrumbs,Typography,Button } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from '@material-ui/icons/Search';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import IntervaloSelect from './IntervaloTabla';
import TablaZona from './TablaXZona'
import CardPerfil  from './CardPerfil';
import ContenedorMapa from './ContenedorMapa';
import ModalSeccionXMun from '../MetaXLocacion/ModSeccXMun'; 
import MapDelegacion from '../MapaEdo';
const ZonaInicio = () => {
  let history = useHistory();     
  const [fSelect, setFSelect] = useState({inicio:new Date(),final:new Date()});
  const[actualizar,setActualizar]=useState(true);
  const[listMuni,setListMuni]=useState([]);
  const [espera,setEspera]=React.useState(false);
  const [muniSelect,setMuniSelect]=React.useState([]);
  const [auxBandera,setAuxBandera]=React.useState(0);
  const[totalPerfil,setTotalPerfil]= useState([]);
  const [arregloColor,setArregloColor] = useState([])  
  const [perfilListMun,setPerfilListMun] = useState([])  
  const [infoNvlCoord,setInfoNvlCoord] = useState([])   
  const [listMunZon,setListMunZon]=useState([])
  const [munSvgSelect,setMunSvgSelect]=useState(0)
  const[modalMetaSecc,setModalMetaSecc]=useState(false);
  const generarLista = e =>{   
    e.preventDefault()    
    setTotalPerfil([])
    setArregloColor([])
    setActualizar(!actualizar)
    setAuxBandera(1)
  }
  useEffect(()=>{
    let usuid =localStorage.getItem('UsuId') ;  
   // let usuTipo =localStorage.getItem('Tipo') ;    
    const source = axios.CancelToken.source();          
    async function conectarJSon()  {       
      setEspera(true) ;        
      let auxU= process.env.REACT_APP_LINK +`/coordinador-list`;               
      let token =localStorage.getItem('token20') ;               
      let dataInfo = qs.stringify({                
          'usuario': usuid,
          'tipo':0,                                  
          'idDispositivo':'w8rf51v21dsd2cs',
          });
      let config = {
          url:auxU ,
          method: 'POST',
          headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
          data: dataInfo,
          cancelToken: source.token
      };
      let aux ={};           
      await axios(config)
      .then(function (response) {                
          aux=  response.data;                           
          if (aux[0].Id != -1) 
          { // console.log(aux);  
            // guardarSinDatos(true)   
            aux.sort(function (a, b) { return a.Id - b.Id; });  
            let arrFiltrado=[]
            aux.forEach((element,index) => {
              if (element.Id!==0) 
              {
                arrFiltrado.push(element)  
              }
              
            });   
            //console.log(arrFiltrado);                                                  
            setListMuni(arrFiltrado)
          }
          else
          { 
            //guardarSinDatos(false)       
          }                                            
      })
      .catch(function (error) {                
        console.log(error);        
        if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
        {
          console.log('datos incorrectos' );  
        }else
        {
          if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
              ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
          {
            console.log('loguearse de nuevo');
            localStorage.clear();                      
            history.push("/");                              
          } 
        }
      });              
      setEspera(false)  ;
    };  
    conectarJSon();   

  },[])

  const guardarMuni=(e)=>{
    //console.log(e);
    if (e!== null && e!== undefined) 
    {
      setMuniSelect(e)    
      //console.log('Con valores');            
    }
    else
    { setMuniSelect([])    
      //console.log("Sin valores")
    }
    setInfoNvlCoord([]) 
    setAuxBandera(0)    
  }

  const letraTipo=(auxNvl,auxNvlId,auxUser)=>{
    //console.log(auxNvl);
    return(
    <span   >
      <Typography color="primary" variant="body2" id={auxUser+auxNvlId} 
        style={{fontSize:.8+'rem',fontWeight:600}}
      >
        {auxUser}
      </Typography>
      <Typography color="textPrimary" variant="body2" id={auxNvl+auxNvlId} 
        style={{fontSize:.7+'rem',textAlign:"center"}}
      >
        {auxNvl}
      </Typography>
    </span>
    )
  }
  const letraTipo2=(auxNvl,auxId,auxUser)=>{
    //console.log(auxNvl);
    return(
    <span   >
      <Typography color="primary" variant="body2" id={auxId} 
        style={{fontSize:.8+'rem',fontWeight:600}}
      >
        {auxUser}
      </Typography>
      <Typography color="textPrimary" variant="body2" id={auxId} 
        style={{fontSize:.7+'rem',textAlign:"center"}}
      >
        {auxNvl}
      </Typography>
    </span>
    )
  }
  const nvlInfo=(lista)=>{
    //console.log(lista);
    return(
    <Box mt={1} mb={2}>
    <Breadcrumbs separator={<NavigateNextIcon  />} aria-label="breadcrumb"  >
      {lista.CZId>0 ? letraTipo(lista.CZNom,lista.CZId,lista.CZUser) : null}
      {lista.CRId>0 ? letraTipo(lista.CRNom,lista.CRId,lista.CRUser) : null}
      {lista.CMId>0 ? letraTipo(lista.CMNom,lista.CMId,lista.CMUser) : null}    
      {lista.CSId>0 ? letraTipo(lista.CSNom,lista.CSId,lista.CSUser) : null} 
      {lista.PrId>0 ? letraTipo(lista.PrNom,lista.PrId,lista.PrUser) : null}   
      {lista.MetaValor.length !== 0 ? letraTipo2(lista.MetaTitulo,"meta-zon1",lista.MetaValor) : null}            
      {lista.SeccionValor.length !== 0 ? letraTipo2(lista.SeccionTitulo,"meta-zon2",lista.SeccionValor) : null}         
    </Breadcrumbs>
    </Box>
    )
  }
  const generaCard=(seleccionado)=>{
    //let auxReOrden=ReOrdenMun(seleccionado)
    let auxMun=listMunZon.filter((muni)=>muni.MuniId===seleccionado)    
    return(
    <Card style={{padding:.3+'rem'}}>
      <Typography>{auxMun[0].MuniNom}</Typography>
    </Card>
    )
  }

  const cardMuni= listMunZon.length!==0 && munSvgSelect!== 0 ? generaCard(munSvgSelect):null
  const modSeccion = modalMetaSecc? <ModalSeccionXMun modalAbierto={modalMetaSecc} nivel={1}
                                      setModalAbierto={setModalMetaSecc}  muniSelect={muniSelect}
                                      nomMuni={muniSelect.Nom} />:null
  return(
  <div>
    <Box display={'flex'} pb={1}>
      <Box >          
        <IconButton color="primary"  aria-label="add circle" 
          component="span" onClick={generarLista}
        >
          <Tooltip title="Buscar">
            <SearchIcon/>
          </Tooltip>
        </IconButton>                                                                                                                              
      </Box>    
      <Box>
        <IntervaloSelect fSelect={fSelect}  setFSelect={setFSelect} />
      </Box>
      <Box pl={1} pt={1}>
      {listMuni.length!==0 ?(
        <Autocomplete
          id="Zona-autocomplete" size='small' options={listMuni}
          onChange={(e,option)=>guardarMuni(option)}          
          getOptionLabel={(option) => option.Nom}
          style={{ width: 12.5+'rem' }}
          noOptionsText={"Sin coincidencias"}
          renderInput={(params) => (
              <TextField {...params} 
              label="Zona" variant="outlined" />
          )}
        />)
      :<LinearProgress/>
      }
      </Box>    
    </Box>   
    <Box display="flex" flexDirection={"row"}>
      {infoNvlCoord.length > 0 ? nvlInfo(infoNvlCoord[0]) : null }
      {infoNvlCoord.length > 0 ?
        <Box alignContent={"center"} ml={2}>
          <Button onClick={()=>setModalMetaSecc(true)} style={{marginTop:1+'rem'}}
          size="small" variant="outlined" color="primary" >Meta</Button>
        </Box>
        :null}
    </Box>
    <Box>
      {totalPerfil.length !== 0 && auxBandera === 1 ? 
      <CardPerfil valor={totalPerfil} />
      :null}
    </Box>
    {  muniSelect.length!==0 && auxBandera===1? 
      <Box display={"flex"} row >
        <TablaZona fSelect={fSelect} actualizar={actualizar} 
          muniSelect={muniSelect} setTotalPerfil={setTotalPerfil} 
          setPerfilListMun={setPerfilListMun} setArregloColor={setArregloColor}
          setInfoNvlCoord={setInfoNvlCoord} setListMun={setListMunZon} 
        />
        {arregloColor.length !== 0 ?
          <Box display={"flex"} flexDirection="column">
          {/* <span style={{marginLeft:2+'rem'}} >
            {cardMuni}
            <ContenedorMapa coloreados={arregloColor} setMunSvgSelect={setMunSvgSelect} />
          </span> */}
          <span style={{marginLeft:1+'rem'}} >
          <MapDelegacion arregloColor={arregloColor}/>
          </span>
          </Box>
          :null
        }
      </Box>
    :null}
    {modSeccion}
  </div>
  );
};

export default ZonaInicio;
