import React from 'react';
import {Breadcrumbs,Typography,Link, Box} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import axios from 'axios';
import qs from 'qs';
import { authUser } from '../funciones/AuthUser'
import { useHistory } from "react-router-dom";
import NombreIni from './NombreInicio'
import ZonaInicio from './ZonaInicio';
import MunicipioInicio from './MuniInicio';
import SeccionInicio from './SeccionInicio'
import RegionInicio from './RegionInicio'
import CoordinadorSecc from './CoorSeccional'
import PromotorInicio from './PromotorInicio';
import PublicoInicio from './PublicoInicio';
import PerfilExterno from '../perfilExt/PExterno'; 

const useStyles = makeStyles((theme) => ({
    textoSelect: {
      '&:hover': {
        color:'#9ccdfa',
      },
      fontSize:1.25+'rem'
    },
}));

const PerfilInicio = () => {
    let history = useHistory();     
    const classes = useStyles();    
    const {usuario,guardarUsuario}=React.useContext(AuthContext);
    const {generales,guardarGenerales}=React.useContext(GeneralesContext); 
    const[listMuni,setListMuni]=React.useState([]);
    const[opcion,setOpcion]=React.useState(0)
    const [espera,setEspera]=React.useState(false);
    const usuID = localStorage.getItem('UsuId');
    React.useEffect(()=>{
        const usu = localStorage.getItem('UsuarioNom');
        
        let usuTipo =localStorage.getItem('Tipo') ;    
        const nvl= localStorage.getItem('Tipo');    
        const source = axios.CancelToken.source();    
      
        async function llamadaListMuni()  {       
            setEspera(true) ;        
            let auxU= process.env.REACT_APP_LINK +`/lugar-municipio-tipo`;               
            let token =localStorage.getItem('token20') ;               
            let dataInfo = qs.stringify({                
                'usuario':usuID,              
                'estado':15,                
                'tipo':usuTipo,
                'idDispositivo':'w8rf51v21dsd2cs',
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {                
                aux=  response.data;                           
                if (aux[0].Id != -1) 
                {   //console.log(aux);  
                    // guardarSinDatos(true)                                                           
                    setListMuni(aux)
                }
                else
                { 
                //guardarSinDatos(false)       
                }                                            
            })
            .catch(function (error) {                
            console.log(error);        
            if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
            {
                console.log('datos incorrectos' );  
            }else
            {
                if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                    ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                {
                console.log('loguearse de nuevo');
                localStorage.clear();                      
                history.push("/");                              
                } 
            }
            });              
            setEspera(false)  ;
        };
      
        const veriSesion =  () => {            
            let auxValidacion=authUser()
            if(!auxValidacion ||(usu===undefined||usu===''||usu===null)||(nvl===undefined||nvl===''||nvl===null)
                ||(usuID===undefined||usuID===''||usuID===null) )
            {   guardarUsuario([])				
                guardarGenerales([])    
                localStorage.clear();            	             
                history.push("/")	                	
            }
            else
            {   
                if (usuario.length==0) 
                {
                    guardarUsuario({Nombre:usu,nivel:nvl});                                         
                    
                }        
                //document.title = 'Listado'   
                llamadaListMuni();                                 
                guardarGenerales({...generales,Titulo:'Listado de Perfiles'})                                                       
            }   
        }
        veriSesion()
    },[])
    
    const selectOpcion =(e,auxValor)=>{
        e.preventDefault();
        //console.log(auxValor);
        setOpcion(auxValor)
    }

    const menuAdmin=(  
            <Breadcrumbs separator="-"   aria-label="breadcrumb"   > {/*nivel administrador */}
               
                <Typography variant="h5" className={classes.textoSelect} 
                    color={opcion === 7 ? "Primary":'textPrimary'} onClick={e=>selectOpcion(e,7)}
                >
                    Zona
                </Typography>
                <Typography variant="h5" className={classes.textoSelect} 
                    color={opcion === 4 ? "Primary":'textPrimary'} onClick={e=>selectOpcion(e,4)}
                >
                    Distrito
                </Typography>
                <Typography variant="h5" className={classes.textoSelect} 
                    color={opcion === 1 ? "Primary":'textPrimary'} onClick={e=>selectOpcion(e,1)}
                >
                    Municipio
                </Typography>
                <Typography variant="h5" className={classes.textoSelect} 
                    color={opcion === 2 ? "Primary":'textPrimary'} onClick={e=>selectOpcion(e,2)}
                >
                    Sección
                </Typography>
                <Typography variant="h5" className={classes.textoSelect} 
                    color={opcion === 6 ? "Primary":'textPrimary'} onClick={e=>selectOpcion(e,6)}
                >
                    Promotor
                </Typography>
                <Typography variant="h5" className={classes.textoSelect} 
                    color={opcion === 3 ? "Primary":'textPrimary'} onClick={e=>selectOpcion(e,3)}
                >
                    General
                </Typography>
               {usuID !== undefined && usuID !== null && (parseInt(usuID) === 1 || parseInt(usuID) === 170303 ) ? 
                <Typography variant="h5" className={classes.textoSelect} 
                    color={opcion === 8 ? "Primary":'textPrimary'} onClick={e=>selectOpcion(e,8)}
                >
                    Público
                </Typography>:null}
                {/* usuID !== undefined && usuID !== null && (parseInt(usuID) === 1 || parseInt(usuID) === 170303 ) ? 
                <Typography variant="h5" className={classes.textoSelect} 
                    color={opcion === 9 ? "Primary":'textPrimary'} onClick={e=>selectOpcion(e,9)}
                >
                    Externo
                </Typography>:null */}
               
            </Breadcrumbs> 
            )

    const menu=(<Breadcrumbs separator="-" aria-label="breadcrumb" > {/*nvl zona */}            
                <Typography variant="h5" className={classes.textoSelect} 
                    color={opcion === 4 ? "Primary":'textPrimary'} onClick={e=>selectOpcion(e,4)}
                >
                    Distrito
                </Typography>
                <Typography variant="h5" className={classes.textoSelect} 
                    color={opcion === 1 ? "Primary":'textPrimary'} onClick={e=>selectOpcion(e,1)}
                >
                    Municipio
                </Typography>
                <Typography variant="h5" className={classes.textoSelect} 
                    color={opcion === 2 ? "Primary":'textPrimary'} onClick={e=>selectOpcion(e,2)}
                >
                    Sección
                </Typography>
                <Typography variant="h5" className={classes.textoSelect} 
                    color={opcion === 6 ? "Primary":'textPrimary'} onClick={e=>selectOpcion(e,6)}
                >
                    Promotor
                </Typography>
                <Typography variant="h5" className={classes.textoSelect} 
                    color={opcion === 3 ? "Primary":'textPrimary'} onClick={e=>selectOpcion(e,3)}
                >
                    General
                </Typography>
                {/* <Typography variant="h5" className={classes.textoSelect} 
                    color={opcion === 8 ? "Primary":'textPrimary'} onClick={e=>selectOpcion(e,8)}
                >
                    Público
                </Typography> */}
            </Breadcrumbs>
            )

    const menu2=(<Breadcrumbs separator="-" aria-label="breadcrumb" >{/*nivel coord distrito*/}
            <Typography variant="h5" className={classes.textoSelect} 
                color={opcion === 1 ? "Primary":'textPrimary'} onClick={e=>selectOpcion(e,1)}
            >
                Municipio
            </Typography>
            <Typography variant="h5" className={classes.textoSelect} 
                color={opcion === 2 ? "Primary":'textPrimary'} onClick={e=>selectOpcion(e,2)}
            >
                Sección
            </Typography>
            <Typography variant="h5" className={classes.textoSelect} 
                    color={opcion === 6 ? "Primary":'textPrimary'} onClick={e=>selectOpcion(e,6)}
                >
                    Promotor
                </Typography>
            <Typography variant="h5" className={classes.textoSelect} 
                color={opcion === 3 ? "Primary":'textPrimary'} onClick={e=>selectOpcion(e,3)}
            >
                General
            </Typography>
            {/* <Typography variant="h5" className={classes.textoSelect} 
                color={opcion === 8 ? "Primary":'textPrimary'} onClick={e=>selectOpcion(e,8)}
            >
                Público
            </Typography> */}
        </Breadcrumbs>
        )  

    const menu3=(<Breadcrumbs separator="-" aria-label="breadcrumb" >{/*nivel coord municipio*/}        
        <Typography variant="h5" className={classes.textoSelect} 
            color={opcion === 2 ? "Primary":'textPrimary'} onClick={e=>selectOpcion(e,2)}
        >
            Sección
        </Typography> 
        <Typography variant="h5" className={classes.textoSelect} 
            color={opcion === 6 ? "Primary":'textPrimary'} onClick={e=>selectOpcion(e,6)}
        >
            Promotor
        </Typography>
        <Typography variant="h5" className={classes.textoSelect} 
                color={opcion === 3 ? "Primary":'textPrimary'} onClick={e=>selectOpcion(e,3)}
            >
            General
        </Typography>  
{/*         <Typography variant="h5" className={classes.textoSelect} 
            color={opcion === 8 ? "Primary":'textPrimary'} onClick={e=>selectOpcion(e,8)}
        >
            Público
        </Typography>  */}    
    </Breadcrumbs>
    )  

    const menu4=(<Breadcrumbs separator="-" aria-label="breadcrumb" >{/*nivel coord seccional*/}        
        {/* <Typography variant="h5" className={classes.textoSelect}  en este nivel se usa coorSeccional
            color={opcion === 2 ? "Primary":'textPrimary'} onClick={e=>selectOpcion(e,2)}
        >
            Sección
        </Typography>  */}
         <Typography variant="h5" className={classes.textoSelect} 
            color={opcion === 2 ? "Primary":'textPrimary'} onClick={e=>selectOpcion(e,2)}
        >
            Sección
        </Typography> 
        <Typography variant="h5" className={classes.textoSelect} 
            color={opcion === 6 ? "Primary":'textPrimary'} onClick={e=>selectOpcion(e,6)}
        >
            Promotor
        </Typography>
        <Typography variant="h5" className={classes.textoSelect} 
            color={opcion === 5 ? "Primary":'textPrimary'} onClick={e=>selectOpcion(e,5)}
        >
            General
        </Typography>  
        {/* <Typography variant="h5" className={classes.textoSelect} 
            color={opcion === 8 ? "Primary":'textPrimary'} onClick={e=>selectOpcion(e,8)}
        >
            Público
        </Typography>   */}   
    </Breadcrumbs>
    )   
    
    const menu5=(<Breadcrumbs separator="-" aria-label="breadcrumb" >{/*nivel coord seccional   en este nivel se usa coorSeccional */}                
        <Typography variant="h5" className={classes.textoSelect} 
            color={opcion === 5 ? "Primary":'textPrimary'} onClick={e=>selectOpcion(e,5)}
        >
            General
        </Typography>      
        {/* <Typography variant="h5" className={classes.textoSelect} 
            color={opcion === 8 ? "Primary":'textPrimary'} onClick={e=>selectOpcion(e,8)}
        >
            Público
        </Typography>  */}
    </Breadcrumbs>
    )   
    const filtraTipo=()=>{
        const nvl= parseInt(localStorage.getItem('Tipo'));   
        //console.log(nvl);
        let opciones=null 
        switch (nvl) {
            case 1 :
                opciones=menuAdmin                
                break;
            case 2:
                opciones=menu5
                break;    
            case 3:
                opciones=menu
                //console.log("coordinador distrito");
                break;    
            case 4:
                opciones=menu2
                //console.log("coordinador distrito");
                break;
            case 5:
                opciones=menu3
                break;
            case 6:
                opciones=menu4
                break;    
                
            default:
                break;
        }
        return(opciones)
    }
    const contenido9 = opcion === 9 ?<PerfilExterno listMuni={listMuni}/>: null
    const contenido8 = opcion === 8 ? <PublicoInicio listMuni={listMuni} /> : contenido9       
    const contenido7 = opcion === 7 ? <ZonaInicio listMuni={listMuni} /> : contenido8       
    const contenido6 = opcion === 6 ? <PromotorInicio listMuni={listMuni} /> :  contenido7       
    const contenido5 = opcion === 5 ? <CoordinadorSecc listMuni={listMuni} /> : contenido6                        
    const contenido4 = opcion === 4 ? <RegionInicio listMuni={listMuni} /> : contenido5                        
    const contenido3 = opcion === 1 ? <MunicipioInicio listMuni={listMuni} /> : contenido4                        
    const contenido2 = opcion === 2 ? <SeccionInicio listMuni={listMuni} /> : contenido3                        
    const contenido = opcion === 3 ? <NombreIni /> : contenido2  

    return( 
    <div>
        <Box display="flex" pt={2} pb={1} style={{justifyContent:'center'}}>
            {filtraTipo()}
        </Box>
        {contenido}
    </div>
    );
};

export default PerfilInicio;
