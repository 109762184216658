import React,{useState} from 'react'
import {Box,Collapse,IconButton,Paper,Table,TableBody,TableCell,LinearProgress,
    TableContainer,TableHead,TableRow,Button,Card,Tooltip} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import { authUser } from '../funciones/AuthUser'
import ModalMapa from './ModalMapa'
import LocationOnIcon from '@material-ui/icons/LocationOn';
import InfoPromo from './ModInfo';
import InfoIcon from '@material-ui/icons/Info';
import ImageIcon from '@material-ui/icons/Image';
import ModalNvoPerfilImg from '../nvoPerfil/ModalNvoPerfImg';
import { generaCoordenadaDinam } from "../funciones/Mapas/FormatoCoord";
import { llamadaApi,ErrorGeneral } from '../funciones/ClienteAxios';
import {exportarArch} from '../funciones/ListaExcel'
import SaveIcon from '@material-ui/icons/Save';
const useStyles = makeStyles({
    root: {
      //width: '100%',
    },
    container: {
      //maxHeight: 550,
      maxHeight: window.innerHeight-252,
      minHeight: 280,
      maxWidth:'100%',
      minWidth:55+'rem',
    },
    container2: {
      maxHeight: 550,
      //maxHeight: window.innerHeight-170,
      //minHeight: 280,
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor: process.env.REACT_APP_Color_Second
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });
  const columnas=[          
  /*{
      id:2,
      titulo:"Reg",                                                
      alinear:'center'
    }, */     
    {
      id:3, titulo:"Registro", alinear:'center'
    }, 
    {
      id:7, titulo:"Sección", alinear:'center'
    },
    {
      id:6, titulo:"Teléfono", alinear:'center'
    },  
    {
      id:10, titulo:"Usuario", alinear:'center'
    }, 
    {
      id:11, titulo:"Nombre",alinear:'center'
    },
    {
      id:9, titulo:"Email", alinear:'center'
    }, 
    {
      id:5, titulo:"Verificación", alinear:'center'
    },     
    {
      id:4, titulo:"Nacimiento", alinear:'center'
    },    
    
  ];

const TablaXSeccion = ({fSelect,actualizar,setActualizar,setArregloColor,seccionSelect,setTotalPerfil,muniSelect,setInfoNvlCoord,setSeccGps,setCoordPerfiles,setAuxMapVar}) => {
    const classes = useStyles();
    let history = useHistory();         
    const usuID = localStorage.getItem('UsuId');
    let Dispvo =localStorage.getItem('Dispvo') ;    
    let auxDispV =Dispvo.length >= 12 ? Dispvo:'w8rf51v21dsd2cs'    
    let fInicio=moment(fSelect.inicio).format("YYYY-MM-DD");
    let fFinal=moment(fSelect.final).format("YYYY-MM-DD");
    let fondo=process.env.REACT_APP_Fondo_Color
    let colSecond=process.env.REACT_APP_Color_Second
    const [datos, guardarDatos] = React.useState([])  
    const[sinDatos,guardarSinDatos]= React.useState(true)
    const [espera,setEspera]=React.useState(false);
    const [selectedID, setSelectedID] = React.useState(null); 
    const [regisSelect, setRegisSelect] = React.useState([]); 
    const [clienteSeleccionado,setClienteSeleccionado]= useState([]);  
    const {usuario,guardarUsuario}=React.useContext(AuthContext);
    const {generales,guardarGenerales}=React.useContext(GeneralesContext); 
    const [infoMapaMod, setInfoMapaMod] = useState(false);
    const [subtInfo, setSubtInfo] = useState("") 
    const [tituloMapa,setTituloMapa] = useState("")
    const [modalInfo, setModalInfo] = useState(false);
    const [modalNvoImg, setModalNvoImg] = React.useState(false);
    const [nvoRegisPerfil, setNvoRegisPerfil] = React.useState([]);
    const [listPlantilla,setListPlantilla]=useState([])
    const [listaExcel, setListaExcel] = React.useState([]);
    
  React.useEffect(()=>
  { 
    const source = axios.CancelToken.source();    
    setAuxMapVar([])
    setSelectedID(null)   
    setRegisSelect([])
    async function conectarJSon()  {       
      setEspera(true) ;
      
      let auxU= process.env.REACT_APP_LINK +`perfil-lista-seccion`;               
      let token =localStorage.getItem('token20') ;               
      let dataInfo = qs.stringify({                
        'usuario':usuID,
        'fini':fInicio+'T00:00:00',
        'ffin':fFinal+'T23:59:59',
        'status':0,          
        'seccion':seccionSelect.Id,
        'idDispositivo':'w8rf51v21dsd2cs',
          });
      let config = {
          url:auxU ,
          method: 'POST',
          headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
          data: dataInfo,
          cancelToken: source.token
      };
      let aux ={};           
      await axios(config)
      .then(function (response) {                
        aux=  response.data;                           
        if (aux[0].Id != -1) 
        { //console.log(aux);  
          guardarSinDatos(true) 
          let auxPendiente=0
          let auxVerificado=0
          let auxRechazado=0
          let auxArrMuni=[],auxArrCoordP=[], arrM=[]
          arrM.push(["#","Sección","Nombre","Teléfono","Email",
            "Fecha Registro","Fecha Nacimiento","Foto"])
          aux.forEach((perfil,index) => {
            if (perfil.StatusId===1) 
            { auxPendiente=auxPendiente+1
            }
            if (perfil.StatusId===2) 
            { auxVerificado=auxVerificado+1
            }
            if (perfil.StatusId===3) 
            { auxRechazado=auxRechazado+1
            }
            let idColor= ordenIdColor(muniSelect )
            auxArrMuni.push({Id:idColor,auxColor:colSecond,IdOriginal:muniSelect})
            let auxFReg=moment.utc( perfil.Fecha).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00"
            ? "" : moment.utc( perfil.Fecha).format('DD/MM/YYYY HH:mm')
            let auxNac=moment.utc( perfil.Nacimiento).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00"
            ? "" : moment.utc( perfil.Nacimiento).format('DD/MM/YYYY') 
            arrM.push([index+1,perfil.SeccNum,perfil.Nombre,perfil.Tel,perfil.Email,auxFReg,auxNac,perfil.Imagen])
            let aux1= generaCoordenadaDinam(perfil.Lat, perfil.Lon)
           auxArrCoordP.push({coordenada:aux1,perfil:perfil.Nombre+"\n"+perfil.Tel})
          });
          //auxTotal.push()
          setListaExcel(arrM)
          setTotalPerfil({
            Valido:auxVerificado,
            Pendiente:auxPendiente,
            Rechazado:auxRechazado})                                                            
          guardarDatos(aux)
          setCoordPerfiles(auxArrCoordP)
          setArregloColor(auxArrMuni)            
        }
        else
        { guardarDatos([])
          setTotalPerfil([])  
          guardarSinDatos(false)     
          setArregloColor([])
          setListaExcel([])  
        }                                            
      })
      .catch(function (error) {                
        console.log(error);        
        if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
        {
          console.log('datos incorrectos' );  
        }else
        {
          if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
              ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
          {
            console.log('loguearse de nuevo');
            localStorage.clear();                      
            history.push("/");                              
          } 
        }
      });              
      setEspera(false)  ;
    };

    async function llamadaPerfilEstrutura()  {                       
      let auxU= process.env.REACT_APP_LINK +`perfil-estructura-perfil`;               
      let token =localStorage.getItem('token20') ;               
      let dataInfo = qs.stringify({                
        'usuario':usuID,          
        'tipo':6,
        'zon':0,
        'reg':0,
        'mun':0,
        'sec':seccionSelect.Id,
        'pro':0,
        'idDispositivo':'w8rf51v21dsd2cs',
          });
      let config = {
          url:auxU ,
          method: 'POST',
          headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
          data: dataInfo,
          cancelToken: source.token
      };
      let aux ={};           
      await axios(config)
      .then(function (response) {                
        aux=  response.data;                           
        if (aux[0].Id != -1) 
        { //console.log(aux);  
          setInfoNvlCoord(aux)             
        }                               
      })
      .catch(function (error) {                
        console.log(error);        
        if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
        {
          console.log('datos incorrectos' );  
        }else
        {
          if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
              ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
          {
            console.log('loguearse de nuevo');
            localStorage.clear();                      
            history.push("/");                              
          } 
        }
      });                      
    };
    const llamadaPlantilla=()=>{
      let data = qs.stringify({
        usuario:usuID,                 
        dominio:"admin.yosoynuevoespacio.mx",                               
        idDispositivo: auxDispV,
    });
      let url = "plantilla-base-dominio";
      function respuestaPlantilla(auxiliar) {
        if (auxiliar[0].Id != -1) 
        { setListPlantilla(auxiliar)           
        }
      }
      llamadaApi(data, url, respuestaPlantilla,ErrorGeneral,history);
    }
    const usu= localStorage.getItem('UsuarioNom');    
    const nvl= localStorage.getItem('Tipo');    

    const veriSesion =  () => {            
      let auxValidacion=authUser()
      if(!auxValidacion ||(usu===undefined||usu===''||usu===null)||(nvl===undefined||nvl===''||nvl===null)
          ||(usuID===undefined||usuID===''||usuID===null) )
      {   guardarUsuario([])				
        guardarGenerales([])    
        localStorage.clear();            	             
        history.push("/")	                	
      }
      else
      {   
        if (usuario.length==0) 
        { guardarUsuario({Nombre:usu,nivel:nvl});                                                       
        }            
        conectarJSon();    
        llamadaPerfilEstrutura()
        llamadaPlantilla()  
        llenaSeccGPS()                                        
      }            
    }        
   veriSesion();	      
      
    return ()=> {
      source.cancel();
    }                
  },[actualizar])

  const llenaSeccGPS=()=>{
    let data = qs.stringify({
      usuario:usuID,                                        
      fini:fInicio+'T00:00:00',
      ffin:fFinal+'T23:59:59',
      status:0,          
      seccion:seccionSelect.Id,
      idDispositivo: auxDispV,
  });
    let url = "perfil-lista-seccion-gps";
    llamadaApi(data, url, respuestaSeccGPS,ErrorGeneral,history);
  }  
 
  function respuestaSeccGPS(auxiliar) {
    if (auxiliar[0].Id !== 0 ) {
        console.log(auxiliar);   
        setSeccGps(auxiliar)    
    }
  }

  const seleccionado=(auxId,perfil)=>{
    setSelectedID(auxId)   
    setRegisSelect(perfil)
    let aux1= generaCoordenadaDinam(perfil.Lat, perfil.Lon)
    if (aux1.lat!==0) {
      let auxSeleccionado=[]
      auxSeleccionado.push({coordenada:aux1,perfil:perfil.Nombre+"\n"+perfil.Tel})
      setAuxMapVar(auxSeleccionado)  
    }else{
      setAuxMapVar([])  
    }
  }

  function ordenIdColor(MuniNum){    
    let auxNvo =MuniNum
    if (auxNvo===25) //si es cuatitlan izcalli se cambia a 121
    { auxNvo=121
    }
    else
    { if (auxNvo>25 && auxNvo<=121 && auxNvo!==59) 
      { if (auxNvo===60) 
        { auxNvo=58   
        }
        else
        { auxNvo=auxNvo-1
        }
      }
    }
    if (auxNvo===108) 
    { auxNvo=20
    }    
    return auxNvo
  } 
 
  function tipoEdoProspecto(auxColorStd,etiqueta)
  { 
    let renglon=(
      <TableCell  align="center" padding={'none'}        
        style={{ color:'white',fontWeight:700 ,width:100+'px',paddingLeft:.4+'rem',paddingRight:.4+'rem'}} 
      >
        <Card style={{color:auxColorStd==="#EBF1F8"? "black":'white', fontWeight:"bold", backgroundColor:auxColorStd}}>
          {etiqueta}
        </Card>
      </TableCell>    )
    return renglon
  } 
 
  function abrirModalMapa(auxSelec){
    let fecha = moment.utc(auxSelec.Fecha).format('YYYY-MM-DD HH:mm:ss') === "1900-01-01 00:00"
    ? "" : moment.utc(auxSelec.Fecha).format('DD/MM/YYYY HH:mm:ss')
    let titulo=`${auxSelec.Id}`;
    setTituloMapa(titulo)
    setSubtInfo(fecha)
    setRegisSelect(auxSelec)
    setInfoMapaMod(true)
  }
  
  function abrirModalInfo(auxSelec){
    setModalInfo(true)    
    let titulo=`Registro ${auxSelec.Id} `;
    setTituloMapa(titulo)
    setRegisSelect(auxSelec)
  }

  const editarPerfil=(e,row,auxValida)=>{
    e.preventDefault()    
    setModalNvoImg(true);        
    setNvoRegisPerfil({Nom:row.Nombre,llave:row.Id})  
  }
  
  const celda=(alinear,estilo,contenido)=>{
    return (
    <TableCell align={alinear} className={classes.tableCell} padding={'none'} style={estilo} >
      {contenido}
    </TableCell>
    )
  }

  function Renglon(props)
  {
    const { row } = props;     
    let leyenda=``
    let tipoColor="inherit"
    let auxValida=1
    if(row.Imagen.length > 0)
    {
      leyenda=`Imagen Cargada`
      tipoColor="primary"
      auxValida=0
    }
    let imgEstilo=selectedID === row.Id ? {width:18+'px',color:'white',marginTop:.2+'rem'} :{width:18+'px',marginTop:.2+'rem'}
    let auxImg = parseInt(usuID) === 1 ?
    (<Tooltip title={leyenda}> 
       <IconButton   aria-label="agregar imagen" size="small" onClick={(e) => editarPerfil(e,row,auxValida)} 
         component="span">
         <ImageIcon style={imgEstilo} color={tipoColor} /> 
       </IconButton>
     </Tooltip> )
   : (<Tooltip title={leyenda}>                   
    <ImageIcon style={imgEstilo} color={tipoColor} /> 
    </Tooltip>)  

    return (
    <React.Fragment>
      <TableRow onClick={ () => { seleccionado(row.Id,row); } }
        className={classes.tableRow} selected={ selectedID === row.Id}        
        classes={{ hover: classes.hover, selected: classes.selected }}        
      >
       <TableCell align="center" padding={'none'} style={{ width:10+'px'}} >
          <Tooltip title={`Información de registro`}>
            <IconButton   aria-label="add circle" size="small" onClick={() => abrirModalInfo(row)} component="span">
              {selectedID === row.Id ? 
                <InfoIcon style={{color:'white',width:18+'px'}} />
                :<InfoIcon style={{width:18+'px'}}color="primary"/>
              }
            </IconButton>                     
          </Tooltip>                                                                                                                                                       
        </TableCell>  
        <TableCell align="center" padding={'none'} style={{ width:10+'px'}} >
          <Tooltip title={`Ver Mapa`}>
            <IconButton   aria-label="add circle" size="small" onClick={() => abrirModalMapa(row)} component="span">
              {selectedID === row.Id ? 
                <LocationOnIcon style={{color:'white',width:18+'px'}} />
                :(row.Lat===0   ? <LocationOnIcon style={{width:18+'px'}} color="error"/>
                :<LocationOnIcon style={{width:18+'px'}} color="primary"/>)
              }
            </IconButton>                            
          </Tooltip>                                                                                                                                              
        </TableCell>    
        {celda("center",{width:10+'px',paddingLeft:.3+'rem'}, auxImg)}        
        {/* celda("center",{ fontWeight:700,width:40+'px', paddingRight:2+'px'}, row.Id) */}
        <TableCell  className={classes.tableCell} align="center" padding={'none'} style={{ width:105+'px',fontSize:12+'px',paddingLeft:8+'px'}} >          
          {moment.utc( row.Fecha).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00"
            ? "" : moment.utc( row.Fecha).format('DD/MM/YYYY HH:mm')}       
        </TableCell>                                  
        {celda("center",{fontWeight:700,width:40+'px', paddingRight:2+'px'}, row.SeccNum)}  
        {celda("center",{ width:50+'px',fontSize:12+'px',paddingLeft:8+'px'}, row.Tel)}  
        {celda("left",{ width:50+'px',fontSize:12+'px',paddingLeft:8+'px'}, row.UserNom)}  
        {celda("left",{width:220+'px',fontSize:12+'px',paddingLeft:8+'px'}, row.Nombre)}  
        {celda("left",{ width:50+'px',fontSize:12+'px',paddingLeft:8+'px'}, row.Email)}          
        {tipoEdoProspecto(row.StatusColor,row.StatusNom)}             
        <TableCell  className={classes.tableCell} align="center" padding={'none'} style={{ width:105+'px',fontSize:12+'px',paddingLeft:8+'px'}} >          
          {moment.utc( row.Nacimiento).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00"
            ? "" : moment.utc( row.Nacimiento).format('DD/MM/YYYY')}       
        </TableCell>                                                                                 
                                                                             
      </TableRow>        
    </React.Fragment>
  );}
  let columnaVacia=<TableCell align="center" padding={'none'} style={{ backgroundColor:fondo,color:'white'}}  ></TableCell>        
  function tablaCompleta(auxlista){
 
  return(
    <TableContainer className={classes.container}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>            
          {columnaVacia}
          {columnaVacia} 
          {usuID && parseInt(usuID) === 1 ?<TableCell align="center" padding={'none'} style={{ backgroundColor:fondo,color:'white'}}  >     
         <IconButton onClick={()=>exportarArch(listaExcel," Lista x Sección "+seccionSelect.Numero.toString())} size="small" >
            <Tooltip title="Exportar a Excel">
              <SaveIcon style={{color:'white'}} size="small" />
            </Tooltip>
          </IconButton>                                  
          </TableCell>
          :  columnaVacia
          }           
          {celda("center",{backgroundColor:fondo,color:'white'}, auxlista.length)}                                                                    
            {columnas.map(row => {              
              return(  
                <TableCell style={{ backgroundColor:fondo,color:'white'}} 
                  key={row.id}    align={row.alinear} padding={'none'} 
                >
                  {row.titulo}
                </TableCell>    
              )                
            })}           
          </TableRow>
        </TableHead>
        <TableBody>
            {auxlista.length!=0 ? (auxlista.map((row) => (
                <Renglon key={row.Id} row={row} />
            ))):null}
        </TableBody>
      </Table>
    </TableContainer>
  )}
const vacia =<TableCell  className={classes.tableCell} align="center"> </TableCell>         
  const tablaSinDatos=()=>{
    return(
    <TableContainer className={classes.container2}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>            
            {columnas.map(row => {              
              return(  
              <TableCell style={{ backgroundColor:fondo,color:'white'}} 
                key={row.id}    align={row.alinear} padding={'none'} 
              >
                {row.titulo}
              </TableCell>    
              )                
            })}                                                
          </TableRow>
      </TableHead>
      <TableBody>
        <TableRow  className={classes.tableRow} > 
          {vacia}
          {vacia}
          {vacia}
          <TableCell  className={classes.tableCell} align="center">Sin </TableCell>                              
          {vacia}
          <TableCell  className={classes.tableCell} align="center">Registros</TableCell>
          {vacia}
          {vacia}
        </TableRow>
      </TableBody>
    </Table>
    </TableContainer>
  )}
     
  const contenido = sinDatos&&datos.length!=0 ?tablaCompleta(datos):tablaSinDatos()
  const tabla = espera ?  (<Box pt={3}><LinearProgress/> </Box>) :( contenido )
 

  const modalMap = infoMapaMod ? <ModalMapa seleccionado={regisSelect} titulo={tituloMapa} subtitulo={subtInfo}
                                    setModalAbierto={setInfoMapaMod } modalAbierto={infoMapaMod}/> : null;     

  const modInfo = modalInfo ? <InfoPromo seleccionado={regisSelect} modalAbierto={modalInfo} 
                                setModalAbierto={setModalInfo} titulo={tituloMapa} /> : null;            
  const modNvoImg = modalNvoImg  && listPlantilla.length!== 0  ? 
                                <ModalNvoPerfilImg modalAbierto={modalNvoImg} setModalAbierto={setModalNvoImg} 
                                  titulo= {nvoRegisPerfil.Nom ? "Perfil "+nvoRegisPerfil.Nom:"Perfil"}  auxPerfImg={listPlantilla}
                                  nvoRegisPerfil={nvoRegisPerfil} actualizar={actualizar} setActualizar={setActualizar}
                                />:null                                                                  
                                                                                                   
  return (
  <div style={{minWidth:50+'rem',maxWidth:60+'rem'}}>        
    {tabla}             
    {modalMap}
    {modInfo}
    {modNvoImg}
  </div>
  )
}

export default TablaXSeccion
