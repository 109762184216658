import React,{useState,useEffect} from 'react';
import DatosCard from './DatosCard';
import Graficas from './GraficasRegion';
import GraficaMunicipio from './GraficasTotPerfil'
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow
        ,Card,TextField,Box, Paper} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TablaMunXRegion from './TablaMunxReg';
import ContenedorMunicipio from './ContenedorMunicipio';
import CardPartidos from './CardPartidos'
import MapDelegacion from '../MapaEdo';
import axios from 'axios';
import {ordenMapBx,ordenIdColor} from "../MapaEdo/OrdenxMapBox"
const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    estiloCard:{
        width: '15rem',
        boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
        color:'#4a4a4a',
        display:'block',
        padding:'1rem',
        maxHeight:'100%',
        minHeight: 280,
        marginRight:'1rem'
    },
    formControl:{
        boxSizing:'border-box',
        fontSize:'1rem',
        position:'relative',
        marginBottom:'1em'
    },
    container: {
      width: '18rem',
      minHeight: 280,
      //minWidth:'100%',
    },
    tablaContainer: {
        width: '18rem',
        maxHeight: window.innerHeight-170,
        minHeight: 280,
        //minWidth:'100%',
      },
    totalEstilo:{
        fontSize:'1.25rem',
        fontWeight:'bold'
    },
    tituloEstilo:{
        paddingBottom:'.25rem',
        fontWeight:'bold'
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor:process.env.REACT_APP_Color_Second
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });
  
const alturaCont = window.innerHeight<937 ? window.innerHeight-140 : window.innerHeight-130

export default function TablaRegional({muni,listRegion, listRegionalTotal}){    
    const classes = useStyles();
    const [seleccionado,setSeleccionado] = useState([]);
    const [muniSelecc,setMuniSelecc] = useState([]);
    const [arregloColor,setArregloColor] = useState([])
    const [promotor, setPromotor] = useState({nombre:""})
    const [idSelec,setIdSelec] = useState(-1)
    const [datos,setDatos] = useState([]);    
    const[arrModificado,setArrModificado]=useState([]);
    const[regionSelect,setRegionSelect]=useState([]);
    const[listPart,setListPart]=useState([])
    const[regSelect,setRegSelect]=useState(0)
    let colMap=process.env.REACT_APP_Color_Mapa
    const {nombre} = promotor;    
    useEffect(()=>{
 
        let auxPerfiles=[]     
 
        auxPerfiles.push({'regionId':0 ,'nombreReg':'EDOMEX','municipios':listRegionalTotal[0]})   
        listRegion.forEach((region)=>{
            if(region.Id!==0)
            {   let perfilTotal =listRegionalTotal.filter( ( municipio) => municipio.RegionId === region.Id )        
                auxPerfiles.push({'regionId':region.Id,'nombreReg':region.Nom,'municipios':perfilTotal})           
            }            
        })

        setDatos(listRegion)
        setArrModificado(auxPerfiles)

        let arregloColoreado=[]
        let auxTotValido=0
        let auxTotPendiente=0
        let auxTotRechazado=0
        let auxTotMeta=0
        let regionDef =auxPerfiles[0].municipios   
        let auxReg=auxPerfiles.filter((mun)=>mun.regionId!==0)
        auxTotValido=regionDef.Valido    
        auxTotPendiente=regionDef.Pendiente 
        auxTotRechazado=regionDef.Rechazado  
        auxTotMeta = regionDef.Meta  

        auxReg.forEach((auxMun)=>{    
            auxMun.municipios.forEach((municipio)=>{
                let auxNvo =municipio.MuniNum            
                
                if (auxNvo > 0) 
                {   auxNvo=ordenIdColor(municipio.MuniNum)
                    ordenMapBx(arregloColoreado,auxNvo,municipio.PartColor) 
                    //arregloColoreado.push({Id:auxNvo,auxColor:municipio.PartColor})                        
                }                 
            })        
            
        })

        let totalSeleccionado={'Pendiente':auxTotPendiente,'Rechazado':auxTotRechazado,'Valido':auxTotValido,'Meta':auxTotMeta}
        setArregloColor(arregloColoreado)     
        //setListPart(auxListColPart)            
        setSeleccionado(totalSeleccionado);
        setIdSelec(auxPerfiles[0].regionId)
        setRegionSelect(auxPerfiles[0])
        
        //la siguiente linea puede generar errores o no en el useEffect
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[muni])          
 
    const RenglonNum =(valorId,regis)=>{
                
        setRegSelect(regis.regionId)
        let arrMuni=regis.municipios
        let arregloColoreado=[]
        let auxTotValido=0
        let auxTotPendiente=0
        let auxTotRechazado=0
        let auxTotMeta=0
        if (arrMuni.length>1) 
        {   arrMuni.forEach((auxMun)=>{
                auxTotValido=auxMun.Valido + auxTotValido
                auxTotPendiente=auxMun.Pendiente + auxTotPendiente
                auxTotRechazado=auxMun.Rechazado + auxTotRechazado
                auxTotMeta = auxTotMeta + auxMun.Meta
                let auxNvo =auxMun.MuniNum
                if (auxMun.MuniNum > 0) 
                {                      
                    auxNvo=ordenIdColor(auxMun.MuniNum)             
                    ordenMapBx(arregloColoreado,auxNvo,colMap)                                           
                }                 
            })
            let datFiltra=arrModificado.filter((region)=>region.regionId!== regis.regionId && region.regionId!==0)
            if (datFiltra.length>0) 
            {
                datFiltra.forEach((auxMun)=>{    
                    auxMun.municipios.forEach((municipio)=>{
                        let auxNvo =municipio.MuniNum            
                        if (auxNvo > 0) 
                        {                 
                            auxNvo=ordenIdColor(municipio.MuniNum)
                            ordenMapBx(arregloColoreado,auxNvo,municipio.PartColor)                                                      
                        }                 
                    })        
                    
                })    
            }            
            let totalSeleccionado={'Pendiente':auxTotPendiente,'Rechazado':auxTotRechazado,'Valido':auxTotValido,'Meta':auxTotMeta}
            setArregloColor(arregloColoreado)
            setIdSelec(valorId);        
            setSeleccionado(totalSeleccionado);
            setMuniSelecc([])
            setRegionSelect(regis)
        }
        else
        {
            if (regis.regionId===0) 
            {   let auxMuni=regis.municipios
                auxTotValido=auxMuni.Valido    
                auxTotPendiente=auxMuni.Pendiente 
                auxTotRechazado=auxMuni.Rechazado  
                auxTotMeta = auxMuni.Meta  
                let datFiltra=arrModificado.filter((region)=>region.regionId !== 0)
                datFiltra.forEach((auxMun)=>{    
                    auxMun.municipios.forEach((municipio)=>{
                        let auxNvo =municipio.MuniNum            
                        if (auxNvo > 0) 
                        {                 
                            auxNvo=ordenIdColor(municipio.MuniNum)   
                            ordenMapBx(arregloColoreado,auxNvo,municipio.PartColor)                                                                      
                        }                 
                    })        
                    
                }) 
                let totalSeleccionado={'Pendiente':auxTotPendiente,'Rechazado':auxTotRechazado,'Valido':auxTotValido,'Meta':auxTotMeta}                               
                setSeleccionado(totalSeleccionado);
                setArregloColor(arregloColoreado)
                setIdSelec(regis.regionId)
                setRegionSelect(regis)
            }
        }
 
    }

    function filtro(){        
        let auxCadena=nombre.trim();       
        let info=[];                        
        let aux=  arrModificado.filter((dat)=>dat.nombreReg.toUpperCase().search(auxCadena.toUpperCase() ) !== -1)         
        info=aux 
        let auxNum=0;
         if (aux!==null||aux!=='') {
            aux.forEach((mun)=>
            {   if (mun.nombreReg!=='EDOMEX')
                {   //info.push(mun);    
                    auxNum++;
                }                
            })
        }         
        return TablaPromo(info,auxNum);
    }

    const onChange = e =>
    {         
        let expNopermitida = new RegExp('[._:$!%-({})/*|]');
        let expMenos = new RegExp('-');
        let expMas = new RegExp('[+]');     
        let expCadena = new RegExp('[A-Za-z0-9]');
        if ((expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && !expMenos.test(e.target.value) 
            && !expMas.test(e.target.value)) || e.target.value===' '||e.target.value==='') 
        {
            setPromotor({
                ...promotor, //copiamos el nombre
                [e.target.name] : e.target.value  
            })
        }
    };
    
    function TablaPromo(auxDatos,total) {
        let numRenglon=0;
        // console.log(auxDatos);
        return(
        <Paper className={classes.table} style={{ width: 13.5+'rem'}}>
        <TableContainer className={classes.tablaContainer} id="tamTablaReg"  >
            <Table stickyHeader aria-label="sticky table">
                <TableHead >    
                    <TableRow>            
                        <TableCell padding='none' style={{backgroundColor:process.env.REACT_APP_Fondo_Color,color:'white',  borderBottom:"1px solid #2A3345"}}>
                            {total} Distritos
                        </TableCell>            
                    </TableRow>                        
                </TableHead>  
                <TableBody>                                  
                    {auxDatos.map((municipio) => {
                        let auxNombre="";
                        //auxNombre=municipio.nombreReg;       
                        if (municipio.regionId===0) 
                        {
                            auxNombre=municipio.municipios.TipoAlias;                       
                        }else{
                            auxNombre=municipio.nombreReg;                   
                        }            
                        numRenglon++;                                      
                        return (                  
                        <TableRow className={classes.tableRow} selected={ idSelec === municipio.regionId} 
                            classes={{ hover: classes.hover, selected: classes.selected }} 
                            key={numRenglon} onClick={ () => RenglonNum(municipio.regionId,municipio) }  
                        >                                
                            <TableCell className={classes.tableCell}   padding='none'>
                                <span>{auxNombre}</span>
                            </TableCell>                                                    
                        </TableRow>              
                        );                        
                        })
                    }                                                
                </TableBody>
            </Table>
        </TableContainer>
        </Paper>
        ); 
    }

  /*   const edomexSvg=<Box id="contenedorMapa"  >                        
                        <ContenedorMunicipio coloreados={arregloColor}/>
                    </Box>  */
                   
    //const valorColores=arrMuniTotal.length!==0 ?llenaSVGTotales(arrMuniTotal):null                        
    const datGeneral = (<Box display="flex" flexDirection="column"  >
                            {seleccionado.length !== 0 ?  
                            <Box display="flex" flexDirection="row" >
                                <DatosCard valor={seleccionado}/>    
                                <CardPartidos  setAuxListPart={setListPart}/>                            
                            </Box>
                            :null}
                            <Box display="flex" flexDirection="row">                                               
                                {/* edomexSvg */} 
                                { arregloColor.length>0?
                                <MapDelegacion regSelect={regSelect} arregloColor={arregloColor}/>
                                :null}
                                <Box style={{marginLeft:2+'rem'}} pt={1} display="flex" 
                                    flexDirection={"column"}
                                >                                    
                                    <Box >
                                        <Graficas valor={seleccionado}  />                             
                                    </Box> 
                                    <br/>
                                    
                                    <Box display={"flex"} >
                                    {seleccionado.length!==0 && regionSelect.regionId!==0?
                                        <TablaMunXRegion seleccionado={regionSelect} 
                                            setMuniSelecc={setMuniSelecc} listPart={listPart}
                                        /> 
                                    : null}
                                    </Box>  
                                    {  muniSelecc.length!==0?
                                        <Box style={{maxHeight:12+'rem'}}>
                                            <GraficaMunicipio valor={muniSelecc} />
                                        </Box>
                                        
                                        :null
                                    }                              
                                </Box>                                
                            </Box>                            
                        </Box>) 

    const contenido = nombre.length>0? (filtro()):(TablaPromo(arrModificado,arrModificado.length-1));    
    const llamada= datos.length !== 0 ?(contenido):(<div style={{paddingTop:1+'rem'}}><h2>No contiene datos</h2></div>)

    return (
    <Box display={'flex'} flexDirection="row" style={{height: alturaCont-10}}>
        <Card className={classes.estiloCard} style={{height:33+'rem'}}>                        
            <TextField className={classes.formControl}
                name="nombre" placeholder="Distrito"
                value={nombre} onChange={onChange}
            />	            
            {llamada} 
            
            {/* valorColores */}           
        </Card>
        {datGeneral}
    </Box>                    
  );
}