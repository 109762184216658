import React from 'react';
import {Breadcrumbs,Typography,Link, Box,  LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import axios from 'axios';
import qs from 'qs';
import { authUser } from '../funciones/AuthUser'
import { useHistory } from "react-router-dom";
//import NombreIni from './NombreInicio'
//import MunicipioInicio from './MuniInicio';
import RegionalInicio from '../regional/RegionalInicio'
import MunicipioInicio from '../municipio/MunicipioInicio';
import ZonaInicio from '../Zona/ZonaInicio'
const useStyles = makeStyles((theme) => ({
    textoSelect: {
      '&:hover': {
        color:'#9ccdfa',
      },
      fontSize:1.25+'rem'
    },
}));

const Principal = () => {
    let history = useHistory();     
    const classes = useStyles();    
    const {usuario,guardarUsuario}=React.useContext(AuthContext);
    const {generales,guardarGenerales}=React.useContext(GeneralesContext); 
    const[listMuni,setListMuni]=React.useState([]);
    const[opcion,setOpcion]=React.useState(1)
    const [espera,setEspera]=React.useState(false);
    
    React.useEffect(()=>{
        const usu = localStorage.getItem('UsuarioNom');
        const usuID = localStorage.getItem('UsuId');

        const nvl= localStorage.getItem('Tipo');    
        const source = axios.CancelToken.source();    
      
        async function llamadaListMuni()  {       
            setEspera(true) ;        
            let auxU= process.env.REACT_APP_LINK +`/lugar-municipio`;               
            let token =localStorage.getItem('token20') ;               
            let dataInfo = qs.stringify({                
                'usuario':usuID,              
                'estado':15,                
                'idDispositivo':'w8rf51v21dsd2cs',
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {                
                aux=  response.data;                           
                if (aux[0].Id != -1) 
                {   //console.log(aux);  
                    // guardarSinDatos(true)                                                           
                    setListMuni(aux)
                }
                else
                { 
                //guardarSinDatos(false)       
                }                                            
            })
            .catch(function (error) {                
            console.log(error);        
            if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
            {
                console.log('datos incorrectos' );  
            }else
            {
                if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                    ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                {
                console.log('loguearse de nuevo');
                localStorage.clear();                      
                history.push("/");                              
                } 
            }
            });              
            setEspera(false)  ;
        };
      
        const veriSesion =  () => {            
            let auxValidacion=authUser()
            if(!auxValidacion ||(usu===undefined||usu===''||usu===null)||(nvl===undefined||nvl===''||nvl===null)
                ||(usuID===undefined||usuID===''||usuID===null) )
            {   guardarUsuario([])				
                guardarGenerales([])    
                localStorage.clear();            	             
                history.push("/")	                	
            }
            else
            {   if (usuario.length==0) 
                {
                    guardarUsuario({Nombre:usu,nivel:nvl});                                                             
                }        
                //document.title = 'Listado'   
                llamadaListMuni();                                 
                guardarGenerales({...generales,Titulo:'Inicio'})                                                       
            }   
        }

        veriSesion()

    },[])
    
    const selectOpcion =(e,auxValor)=>{
        e.preventDefault();
        //console.log(auxValor);
        setOpcion(auxValor)
    }
    
    const menu=(<Breadcrumbs separator="-" aria-label="breadcrumb" >                
                {/* 
                */}
                 <Typography variant="h5" className={classes.textoSelect} 
                    color={opcion === 3 ? "Primary":'textPrimary'} onClick={e=>selectOpcion(e,3)}
                >
                    Zona
                </Typography> 
                <Typography variant="h5" className={classes.textoSelect} 
                    color={opcion === 1 ? "Primary":'textPrimary'} onClick={e=>selectOpcion(e,1)}
                >
                    Distritos
                </Typography>
                <Typography variant="h5" className={classes.textoSelect} 
                    color={opcion === 2 ? "Primary":'textPrimary'} onClick={e=>selectOpcion(e,2)}
                >
                    Municipios
                </Typography>                
            </Breadcrumbs>
            )
    
    const contenido3 = opcion === 3 ? <ZonaInicio listMuni={listMuni} /> : null    
    const contenido2 = opcion === 2 ? <MunicipioInicio listMuni={listMuni} /> : contenido3                        
    const contenido = opcion === 1 ? <RegionalInicio /> : contenido2        
    const auxContenido=espera?     (<Box pt={3}>
        <LinearProgress/> 
        </Box>)  :contenido
   
  return( 
  <div>
    <Box display="flex" pt={1}  style={{justifyContent:'center'}}>
      {menu}
    </Box>
      {auxContenido}
  </div>);
};

export default Principal;