import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { TextField,FormControl,//Box,Tooltip,
        //FormLabel,FormControlLabel,RadioGroup,Radio,LinearProgress,   
        InputAdornment,IconButton,Input,     
        InputLabel,Select,MenuItem} from '@material-ui/core' 
//import DateFnsUtils from '@date-io/date-fns'; // choose your lib
//import esLocale from "date-fns/locale/es";
//import {DatePicker,MuiPickersUtilsProvider} from '@material-ui/pickers';
//import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import ModalActualiza from '../generales/ModalCerrarActualiza';
import moment from 'moment'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '20ch',
      }, 
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      Textnumero: {
        width: 5+"px",
      },
      formControlFull: {
        //margin: theme.spacing(1),
        //minWidth: 120,
        width:5+"rem"
      },
      margin: {
        margin: theme.spacing(1),
      },
    },
  }));
const EditarUsuario = ({modalAbierto,setModalAbierto ,titulo,setActualizar,actualizar,listTipoUsu,usrSelecc,subtitulo }) => {
    let history = useHistory();
    const classes = useStyles();
  
    const[nvoCliente,setNvoCliente]=React.useState({Nombre:'',
                                                FNacimien:'Mon Oct 25 1900 16:42:31 GMT-0600 (hora de verano central)',
                                                Email:'' ,Pass:'',Tel:'',TipoUsu:2,usuNick:'',idUsu:'',stdUsu:''
                                            });                                                
    const [auxCorreo,setCorreo]=React.useState('');
    const [espera,setEspera]=React.useState(false);
    const [mensaje, setMensaje] = React.useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = React.useState("success");
    const [btnDeshabilitado,setBtnDeshabilitado]= React.useState(false)
    const [aviso, setAviso] = React.useState(false);
    const[visiblePwd,setVisiblePwd]=React.useState(false)    
    const[cambioEfectivo,serCambioEfectivo] =React.useState(false)    
    const{  Nombre,FNacimien,Email,Pass,Calle,Tel,TipoUsu,usuNick,idUsu,stdUsu}=nvoCliente
    let colorCheckR='#0EA7E0'
    const source = axios.CancelToken.source();  
    const auxNvl= localStorage.getItem('Tipo');    
    React.useEffect(()=>{
        console.log(usrSelecc.Tipo);
        setNvoCliente({
            ...nvoCliente,
            Nombre: usrSelecc.Nombre,Email:usrSelecc.Email ,Pass:usrSelecc.Pass 
            ,Tel:usrSelecc.Tel ,TipoUsu:usrSelecc.Tipo ,usuNick:usrSelecc.Usuario
            ,idUsu:usrSelecc.Id ,stdUsu:usrSelecc.Status
        })
        setCorreo(usrSelecc.Nombre)
    },[])
               
    
    const onChange = e =>
	{   //let expNopermitida = new RegExp('[#._:!%-({})/*=?|]');
        let expNopermitida = new RegExp('[%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');     
        //let expCadena = new RegExp('[A-Za-z0-9$]');
        let expCadena = new RegExp('[A-Za-z0-9.$@#_&-+()/:;!?,<>=]');  
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
                && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
                || e.target.value===' '|| e.target.value==='') 
        {
            setNvoCliente({
                ...nvoCliente, //copiamos el nvocliente
                [e.target.name] : e.target.value//.toUpperCase()  
            })            
        }		
	};      

    
    const onChangeSinValidar = e =>
	{   
        setNvoCliente({
            ...nvoCliente,
            [e.target.name] : e.target.value//.toUpperCase()  
        })            
        		
	};   
    const onChangeSinValidar2 = e =>
	{   
        setCorreo( e.target.value)            
        		
	};   
    const onChangeNum = e =>
	{   let expNopermitida= new RegExp('[A-Za-z$@#_&-+()/:;!?,<>=]');  
        //let expNopermitida = new RegExp('[-%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('-');     
        let expCadena = new RegExp('[.0-9$]');
        //let expCadena = new RegExp('[A-Za-z0-9$@#_&-+()/:;!?,<>=]');  
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
                && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
                || e.target.value===' '|| e.target.value==='') 
        {
            setNvoCliente({
                ...nvoCliente, //copiamos el nvocliente
                [e.target.name] : e.target.value//.toUpperCase()  
            })            
        }		
	}; 

    const onChangeNumTel = e =>
	{   let expNopermitida= new RegExp('[A-Za-z$@#_&-+()/:;!?,<>=]');  
        //let expNopermitida = new RegExp('[-%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('-');     
        let expCadena = new RegExp('[.0-9$]');
        //let expCadena = new RegExp('[A-Za-z0-9$@#_&-+()/:;!?,<>=]');  
    
        if (  e.target.value.length<=10 &&( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
            && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
            || e.target.value===' '|| e.target.value==='') 
        {
            setNvoCliente({
                ...nvoCliente, //copiamos el nvocliente
                [e.target.name] : e.target.value//.toUpperCase()  
            })            
        }		    

	}; 
 
    const onChangeSelect = e =>
	{   e.preventDefault();
        setNvoCliente({
            ...nvoCliente, 
            [e.target.name] : e.target.value  
        })                       
	};      
        
    const guardaCheckRad = e =>
    {  //console.log(e);
        setNvoCliente({
            ...nvoCliente,
            [e.target.name] : e.target.value  
        })  
    };     

    const guardaCheckRad2 = e =>
    {  
        setNvoCliente({
            ...nvoCliente,
            Nacionalidad : e.target.value  
        })  

    };   
    
    async function llamadaNvoCliente( )  {       
        setEspera(true);
        let auxU=process.env.REACT_APP_LINK +`/usuario-editar`;                               
        let token =localStorage.getItem('token20') ;               
        let usuId =localStorage.getItem('UsuId') ;                                      
        let dataInfo = qs.stringify({     
            'Llave':idUsu,                                                              
            'Tipo':TipoUsu,//parseInt(TipoUsu),
            'Estatus':stdUsu,
            'Nombre':Nombre,
            'Usuario':usuNick,
            'Pass':Pass,
            'Email':Email.length===0? "": Email,
            'Telefono':Tel,             
            'idDispositivo':'451224452121'
            });
        let config = {
            url:auxU ,
            method: 'POST',
            headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
            data: dataInfo,
            cancelToken: source.token
        };
        let aux ={};           
        await axios(config)
        .then(function (response) {              
          aux=  response.data;                                          
            console.log(aux)
          if (aux.length!==0) 
          {                
            //setNvoRegistro(aux[0]);  
            //setActualizar( !actualizar)              
            setBtnDeshabilitado(true)                                             
            let mensaje="Se edito correctamente el Usuario."
            setTipoAdvertencia("success")      
            setMensaje(mensaje);
            setAviso(true)     
            serCambioEfectivo(true)
          }                                            
        })
        .catch(function (error) {                  
            console.log(`Error: ${error}`);              
            if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
            {
                console.log('datos incorrectos' );  
            }else
            {
                if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                    ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                {
                console.log('loguearse de nuevo');
                localStorage.clear();                      
                history.push("/");                              
                } 
            }
        });             
        setEspera(false);         
    };

    const onChangeCorreo = e =>
	{   //let expNopermitida = new RegExp('[#._:!%-({})/*=?|]');
        let expNopermitida = new RegExp('[%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');     
        //let expCadena = new RegExp('[A-Za-z0-9$]');
        let expCadena = new RegExp('[A-Za-z0-9$@#_&-+()/.;!?,<>=]');  
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && !expMenos.test(e.target.value) &&
            !expMas.test(e.target.value) ) || e.target.value===' '|| e.target.value==='') 
        { 
            setNvoCliente({
                    ...nvoCliente, //copiamos el nvocliente
                    [e.target.name] : e.target.value  
                })
            if (aviso) 
            {   setMensaje('');
                setAviso(false);
            }
        }		
	};    

    const verificaNulos = (campo,descripcion)=>{
        let respuesta =false;        
          
        if( (typeof campo !== 'number' && typeof campo === 'string' 
            && (campo.trim() === '' || campo.trim() === null) )
        ) 
        {   respuesta=true;        
            console.log(campo+' '+descripcion);                  
            setMensaje('No se lleno el campo '+descripcion);
            setTipoAdvertencia("warning")  
            setAviso(respuesta);
        }
        return(respuesta);
    }

    const verificaINE = (campo,descripcion)=>{
        let respuesta =false;        
          
        if( ( (campo.length===0 || campo.trim() === null || campo.length<18) )
        ) 
        {   respuesta=true;        
            console.log(campo+' '+descripcion);                  
            setMensaje('No se lleno el campo '+descripcion);
            setTipoAdvertencia("warning")  
            setAviso(respuesta);
        }
        return(respuesta);
    }

    const verificaNulosSelect = (campo,descripcion)=>{
        let respuesta =false;              
        if(campo === '' || campo === null ||campo == 0 )
        {   respuesta=true;                             
            setMensaje('Debe seleccionar campo '+descripcion);
            setTipoAdvertencia("warning")  
            setAviso(respuesta);
        }console.log(campo+descripcion);  
        return(respuesta);
    }

  /*   const verificaCurp=(Curp)=>
    {   let respuesta =false;        
        let expCadena = new RegExp('[A-Z]');
        let expNumero =  new RegExp('[0-9]');
        if (Curp.trim().length===18) 
        {
            let auxnom=Curp.substring(0,4);
            let auxfech=Curp.substring(4,10);
            let auxdesc=Curp.substring(10,16);
            let auxgen=Curp.substring(10,11);
            let auxnumer2=Curp.substring(16,18);
            if ( ( expCadena.test(auxnom) && !expNumero.test(auxnom) ) && ( expNumero.test(auxfech) && !expCadena.test(auxfech) ) 
             &&( !expNumero.test(auxdesc) && expCadena.test(auxdesc)) && ( expCadena.test(auxgen)&& !expNumero.test(auxgen) )
             &&(expNumero.test(auxnumer2) && !expCadena.test(auxnumer2) ) ) 
            {
                if (auxgen==='H'||auxgen==='M') 
                {   
                    respuesta =true ;                    
                }   
                else
                {
                    setMensaje('Debe escribir un CURP valido ' );
                    setTipoAdvertencia("warning")  
                    setAviso(true);
                }
            }
            else
            {   console.log(Curp);
                setMensaje('Debe escribir un CURP valido ' );
                setTipoAdvertencia("warning")  
                setAviso(true);
            }
        } else {
            
            setMensaje('Campo CURP incompleto, debe escribir un CURP valido ' );
            setTipoAdvertencia("warning")  
            setAviso(true);
        }
        
        return(respuesta);
    } */

    const verificaNombre=(nombre,appat,apmat)=>
    {   let respuesta =false;        
        let expCadena = new RegExp('[A-Z]');
        let expNumero =  new RegExp('[0-9]');
        if (!verificaNulos(nombre,'Nombre') && !verificaNulos(appat,'Apellido Paterno') && !verificaNulos(apmat,'Apellido Materno')  ) 
        {        
            if( expCadena.test(nombre) && !expNumero.test(nombre) )
            {
                if(  expCadena.test(appat) && !expNumero.test(appat) )
                {
                    if ( expCadena.test(apmat) && !expNumero.test(apmat)  ) 
                    {  
                        respuesta=true;                     
                    }else
                    {   setMensaje('Debe escribir un Apellido Materno  valido ');
                        setTipoAdvertencia("warning")  
                        setAviso(true);      
                    }
                }
                else
                {   setMensaje('Debe escribir un Apellido Paterno  valido ');
                    setTipoAdvertencia("warning")  
                    setAviso(true);
                }
            }
            else
            {   setMensaje('Debe escribir un Nombre  valido ' );
                setTipoAdvertencia("warning")  
                setAviso(true);
            }    
        }    
        return(respuesta);
    }
    
    const verificaCorreo=(correo)=>
    {   let respuesta =false;        
        let expCadena = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');        
        if( correo.trim() === '' || correo.trim() === null  )
        {  // respuesta=true;      
            setMensaje('Campo Email vacio, debe llenar el campo ' );
            setTipoAdvertencia("warning")  
            setAviso(true);                     
        }
        else{
            if ( expCadena.test(correo)  ) 
            {   respuesta=true;
            }
            else
            {   setMensaje('Debe escribir un Correo valido ' );
                setTipoAdvertencia("warning")  
                setAviso(true);                           
            }            
        }
        return(respuesta);
    }  
  
    /* const veriNuloNum = (campo,descripcion)=>{
        let respuesta =false; 
        console.log(descripcion+ ' '+campo); 
        console.log(campo);                                 
        if((campo==='0'||campo===0 )|| campo === null ||campo === ""|| campo === " ")
        {   respuesta=true;                    
            setMensaje('No se lleno el campo '+descripcion);
            setTipoAdvertencia("warning")  
            setAviso(respuesta);
        }
        return(respuesta);
    } */

    const mandaAlerta=(mensaje,tipoAdvert)=>
    {
        setMensaje(mensaje);
        setTipoAdvertencia(tipoAdvert)  
        setAviso(true);      
    }
 
    function valContra() {
        let respuesta=false
        if ( !verificaNulos(Pass,'Contraseña') ) 
        {            
            if (Pass.length>=4 ) 
            {
                respuesta=true    
            }else
            {
                //mandaAlerta('Minimo 4 caracteres en la contraseña',"warning") 
                mandaAlerta('Campo Contraseña incompleto, debe escribir una Contraseña valida',"warning") 
            }
        }        
        return respuesta;
    }

    function valDireccion() 
    {   let respuesta=false
        if ( !verificaNulos(Calle,'Calle') ) 
        {   if (Calle.length>=4 ) 
            {                
                respuesta=true                    
            }else
            {   mandaAlerta('Campo Calle incompleto, debe escribir una Calle valida',"warning") 
            }
        }        
        return respuesta;
    }

    function validaVariable(auxCampo,auxMensaje,auxTamanio) 
    {   let respuesta=false
        if ( !verificaNulos(auxCampo,auxMensaje) ) 
        {   if (auxCampo.length>=auxTamanio ) 
            {                
                respuesta=true                    
            }else
            {            
                mandaAlerta('Campo '+auxMensaje +' incompleto, debe escribir un '+ auxMensaje+' valido',"warning") 
            }
        }        
        return respuesta;
    }

    function valFechNacimiento( ) {
        let respuesta=false
        if ( moment( FNacimien).format('YYYY-MM-DD') !== '1900-10-25'   ) 
        {   respuesta=true
        }
        else
        {   mandaAlerta('Seleccionar Fecha de Nacimiento',"warning") 
        }
        return respuesta
    }

    function verificaUsu() 
    {   let respuesta=false
        if ( !verificaNulos(Email,'Email') ) 
        {   if (Email.length>=8 ) 
            {                
                respuesta=true                    
            }else
            {   mandaAlerta('Campo Email incompleto, minimo 8 caracteres , debe escribir un Email valido',"warning") 
            }
        }        
        return respuesta;
    }

  /*   function guardarDatos(e)
    {   e.preventDefault()
        console.log("guardando  modal");
        //let auxFechaAlt=moment( new Date()).format('YYYY-MM-DDTHH:mm:ss')
        let auxFechaNacimient=moment( FNacimien).format('YYYY-MM-DD')    
        let auxFech=calcularEdad(auxFechaNacimient)
        console.log(calcularEdad(auxFechaNacimient));           
        if (  verificaNombre(Nombre,Paterno,Materno)&&valFechNacimiento()&& (!verificaINE(Curp,'CURP'))// && verificaCurp(Curp)
            && !verificaINE(NumIdentifi,'INE') && verificaUsu() && valContra()) // se cambio el verificar email por usuario !verificaNulos(Email,'Usuario') 
        {
            if ( !verificaNulosSelect(Genero,'Genero')  &&valDireccion() &&!verificaNulos(CExt,'NoExt') 
              && validaVariable(Cel,'Celular',10)  && validaVariable(Tel,'Telefono',10)  
            && !verificaNulos(Seccion,'Sección')  && !verificaNulos(Colonia,'Colonia')
                
            ) 
            {
                setMensaje('');
                //setTipoAdvertencia(tipoAdvert)  
                setAviso(false); 
                console.log(calcularEdad(auxFechaNacimient));
                llamadaNvoCliente( auxFechaNacimient+'T00:00:00',calcularEdad(auxFechaNacimient)) 
                //llamadaNvoCliente(auxFech)
                            
            }          
        }                                  
    }    */
  
    function guardarDatos(e)
    {   e.preventDefault()        
 
        if (!verificaNulos(TipoUsu,'Tipo') && validaVariable(Nombre,'Nombre',7) &&validaVariable(usuNick,'Usuario',4)   // se cambio el verificar email por usuario !verificaNulos(Email,'Usuario') 
            && valContra() //&& validaVariable(Tel,'Telefono',10)
            )
        {
            /* if (verificaUsu() ) 
            {
                
            } */   
            setMensaje('');                
            setAviso(false);                 
            llamadaNvoCliente()                        
        }                                  
    }   
    const guardarTipo=(e)=>{    
        if (e!== null && e!== undefined) 
        { //setMuniSelect(e)       
            setNvoCliente({
                ...nvoCliente,
                TipoUsu : e.Id  
            })      
        }else
        { setNvoCliente({
            ...nvoCliente,
            TipoUsu : ""  
        })           
        }        
    }
    const guardarTipoSelect=(e)=>{    
        if (e!== null && e!== undefined) 
        { //setMuniSelect(e)       
            setNvoCliente({
                ...nvoCliente,
                TipoUsu :e.target.value 
            })      
        }else
        { setNvoCliente({
            ...nvoCliente,
            TipoUsu : ""  
        })           
        }        
    }
    const mostrarPwd = () => {        
        setVisiblePwd(!visiblePwd)
      };
    
    const mostrarPwdUp = (event) => {
        event.preventDefault();
        //setVisiblePwd(!visiblePwd)
    };

    const DatosGenerales=(
        <div>
            <form autoComplete='off' > 
            {listTipoUsu.length!==0 &&auxNvl!==undefined && parseInt(auxNvl)===1 ?                
               <FormControl  style={{paddingRight:1+'rem', minWidth:14+'rem',paddingBottom:1+'rem'}} >
                   <InputLabel id="tipo">Tipo</InputLabel>
                   <Select  
                     label="Tipo" id="TipoUsu" name="TipoUsu"
                     value={TipoUsu} onChange={guardarTipoSelect}
                   >
                    {listTipoUsu.map((secc, index) => {
                        if(secc.Id>0) {
                        return(
                        <MenuItem value={secc.Id} key={secc.Id}>
                            <em>{secc.Nom }</em>
                        </MenuItem>
                        )}
                    } )}
                    

                   </Select>
                </FormControl>
            :   null
            }
            <br/>
 
            <TextField required id="Nombre-input" onChange={onChange} style={{paddingBottom:.5+'rem',width:23+'rem'}}
                label="Nombre" value={Nombre} name='Nombre'size="small" // defaultValue="Nombre"                
                autoComplete='off'
            />
            <br/>
            <TextField  id="usu-input1" onChange={onChange} style={{width:11.5+'rem',paddingRight:.8+'rem',paddingBottom:.5+'rem',}}
                label="Usuario"  value={usuNick} name='usuNick' size="small"//defaultValue="Usuario"
                autoComplete='off' disabled
            />      
  
            <FormControl  size="small" style={{width:11.5+'rem'}} >
                <InputLabel htmlFor="pwd-usu1-txt" size="small">
                    Contraseña
                </InputLabel>
                <Input
                    id="input-p-usu1"
                    type={visiblePwd ? 'text' : 'password'}
                    value={Pass}  autoComplete='do-not-autofill' name="Pass"
                    onChange={onChange } size="small"
                    endAdornment={
                        <InputAdornment position="end" size="small">
                            <IconButton size="small" 
                                aria-label="icono password visibility"
                                onClick={mostrarPwd}  onMouseDown={mostrarPwdUp}
                            >
                            {visiblePwd ? <Visibility size="small"/> : <VisibilityOff size="small"/>}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>  
            <br/>
            <TextField  id="CInt-input" onChange={onChangeNumTel} style={{paddingRight:1+'rem',width:7.5+'rem'}}
                label="Teléfono" defaultValue="Tel" value={Tel} name='Tel' size="small" type="number"
               inputProps= {{ maxlength: 10 }}
               autoComplete='off'
            /> 
            <TextField  id="Email-input" onChange={onChange} style={{paddingBottom:1+'rem',width:15.5+'rem'}}
                label="Email"  value={Email} name='Email' size="small"//defaultValue="Usuario"
                autoComplete='off'
            /> 
        </form>
        </div>
 
    )       
    const cerrarBorrar = () => {    
        //setOpen(false);        
        setAviso(false);
        setModalAbierto(false);
        if (cambioEfectivo) 
        {
            setActualizar(!actualizar)
        }
    };

    return (
        <>           
         <ModalActualiza
            titulo={titulo} modalAbierto={modalAbierto}
            setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
            mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
            aviso={aviso} btnDeshabilitado={btnDeshabilitado}
            setAviso={setAviso} tamanio={'md'} cargando={espera}      
            subtitulo={subtitulo}  cerrarBorrar={cerrarBorrar}
        >
            {DatosGenerales}
        </ModalActualiza> 
            
        </>
    )
}

export default EditarUsuario
