import React,{useEffect, useState} from 'react';
import {Box,LinearProgress, Typography,Checkbox,FormControlLabel} from "@material-ui/core";
import MapaMarcadores from './MapaConsultaMarcador';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import {ErrorGeneral, llamaApiCargaToken} from "../funciones/ClienteAxios";
import {generaCoordenadaDinam} from '../funciones/Mapas/FormatoCoord'
import Brightness1Icon from '@material-ui/icons/Brightness1';
export default function MapaPanelMunicipio ({estadoId,munId}){
    const history = useHistory();
    const source = axios.CancelToken.source();
    const usuid =localStorage.getItem('UsuId') ; 
    const Dispvo = localStorage.getItem("Dispvo");
    const auxDispV = Dispvo.length >= 12 ? Dispvo : "451224452121";           
    const [espera,setEspera] = useState(true)
    const [poliLocal,setPoliLocal] = useState([])
    const [centro,setCentro] = useState({lat: 19.493815, lng: -99.1137317});
    const [cambiaMarcador,setCambiaMarcador] = useState(true);    
    const [banderaError,setBanderaError] = useState("");    
    const [listMapProm,setListMapProm] = useState([])    
    const [listApoyos,setListApoyos] = useState([]) 
    const [listRecorrido,setListRecorrido] = useState([]) 
    //const [ocultar,setCambiaMarcador] = useState(true);   
    const [checked, setChecked] = useState(false);
    const [checkApoyo, setCheckApoyo] = useState(false);
    const [checkRecorrido, setCheckRecorrido] = useState(false);

    useEffect(()=>{                       
        getPoligonos();                
        limpiaCheck()     
        return ()=> {
            source.cancel();
        }            

    },[munId])

    useEffect(()=>{                                              
        
        if (checked) 
        {
            llamaMapAvance()    
        }else{
            setListMapProm([])
        }        

    },[checked])

    useEffect(()=>{                                              
        
        if (checkApoyo) 
        {
            llamaMapApoyo()    
        }else{
            setListApoyos([])
        }        

    },[checkApoyo])

    useEffect(()=>{                                                      
        if (checkRecorrido) 
        {   llamaRecorrido()    
        }
        else
        {   setListRecorrido([])
        }        
    },[checkRecorrido])

    const limpiaCheck=()=>{
        setChecked(false)
        setCheckApoyo(false)
        setCheckRecorrido(false)
        //console.log("despues del cambio");
    }
 
    const llamaMapAvance = () => {        
        let data = qs.stringify({
            Usuario:usuid,Edo:estadoId,Muni:munId,
            Secc:0, Status:0,idDispositivo: auxDispV,
        });
        let url = "perfil-mapa-avance";

        function respuesta(auxiliar) {
            console.log(auxiliar);
            if (auxiliar[0].Id != -1) {
                return setListMapProm(auxiliar)
            }
        }    
        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
    }

    const llamaMapApoyo = () => {        
        let data = qs.stringify({
            Usuario:usuid,Edo:estadoId,Muni:munId,
            Secc:0, Status:0,idDispositivo: auxDispV,
        });
        let url = "apoyo-mapa-avance";

        function respuesta(auxiliar) {
            console.log(auxiliar);
            if (auxiliar[0].Id != -1) {
                return setListApoyos(auxiliar)
            }
        }    
        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
    }

    const llamaRecorrido = () => {        
        let data = qs.stringify({
            Usuario:usuid,Edo:estadoId,Muni:munId,
            Secc:0, Status:0,idDispositivo: auxDispV,
        });
        let url = "recorrido-mapa-avance";

        function respuesta(auxiliar) {
            if (auxiliar[0].Id != -1) {
                return setListRecorrido(auxiliar)
            }
        }    
        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
    }

    const getPoligonos = ( ) => {
        setPoliLocal([])
        setCentro([])
        let data = qs.stringify({
            usuario: usuid, estado:estadoId,
            municipio:munId, idDispositivo: 'csdq21weddewde' 
        });
        let url = "lugar-seccion-poligono";

        function respuesta(auxiliar) 
        {      // console.log(auxiliar);         
            if (auxiliar[0]?.Id !== -1 &&auxiliar[0].gps ) 
            {   
                setPoliLocal(auxiliar);   
                let auxCoord=generaCoordenadaDinam(auxiliar[0].gps[0].Lat, auxiliar[0].gps[0].Lon)  
                /* if (munSelect.Lat && munSelect.Lon) {
                    setCentro({lat: munSelect.Lat, lng: munSelect.Lon})       
                }
                else
                {
                    setCentro(auxCoord)   
                } */
                setCentro(auxCoord)   
                setBanderaError("")     
            }  
            else{
                setBanderaError("Municipio sin Coordenadas")     
            }
        }    
        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
    }
    
    const handleChange = () => {
        setChecked(!checked)
    };
    
    const onChangeApoyo = () => {
        setCheckApoyo(!checkApoyo)
    };

    const onChangeRecorrido = () => {
        setCheckRecorrido(!checkRecorrido)
    };

    const elementos = espera ? (<Box style={{marginTop:'2rem',marginLeft:'1em',width:'85%'}} > 
                                    <LinearProgress/> 
                                </Box>) :banderaError.length===0? <MapaMarcadores poligonos={poliLocal}  centro={centro} 
                                cambiaMarcador={cambiaMarcador} setCambiaMarcador={setCambiaMarcador}     zoom={12.5}                                 
                                listMapProm={listMapProm} listApoyos={listApoyos} listRecorrido={listRecorrido}
                            />:<Typography>{banderaError}</Typography>
                            ;
    
    return (
    <div style={{display:'flex',justifyContent:'center',marginTop:'.5rem'}}>    
    <Box>
        <Box display="flex">
            <FormControlLabel
                disabled={espera} label="Perfiles" size={'small'}
                control={<Checkbox color="primary"  checked={checked} name={"checked"} />}
                labelPlacement="end" onChange={handleChange}
            />
            <Box display="flex"  alignItems="center" ml={2}>
                <FormControlLabel
                    disabled={espera} label="Apoyos" size={'small'}
                    control={<Checkbox color="primary" checked={checkApoyo} name={"checkApoyo"}/>}
                    labelPlacement="end" onChange={onChangeApoyo}
                /> 
                <Brightness1Icon fontSize="small" style={{color: "#48bd4c"}} />
            </Box>
           <Box display="flex" alignItems="center" ml={3} >
                <FormControlLabel
                    disabled={espera} label="Recorridos" size={'small'}
                    control={<Checkbox color="primary" checked={checkRecorrido} name={"checkRecorrido"}/>}
                    labelPlacement="end" onChange={onChangeRecorrido}
                />
           
                <Brightness1Icon fontSize="small" style={{color: "#182ac9"}} />
            </Box>
            <Box display="flex" alignItems="center" ml={15} >
                <Typography>Sin Coordenadas</Typography>
                <Brightness1Icon fontSize="small" style={{color: "#fb8006"}} />
            </Box>
        </Box>
        {elementos}
    </Box>
        
    </div>
    );
}
